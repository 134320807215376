<template>
    <div>
        <v-card >
            <v-card-title class="blue-grey white--text">AJUSTE</v-card-title>
            <v-card-text>
                <v-form>
                    <v-container>
                        <v-row>
                            <v-col cols="8" md="2">
                                <v-text-field class='text-caption' v-model="sku" :counter="15" label="SKU" :disabled="disabledSku" :filled="filledSku" outlined dense required></v-text-field>
                            </v-col>
                            <v-col cols="4" md="1"><v-btn @click="verSku(sku)" class="mt-1" small color="teal" dark rounded><v-icon small>search</v-icon></v-btn></v-col>
                            <v-col cols="12" md="2">
                                <v-alert class='text-caption' v-if="existe=='si'" dense text type="success">
                                    Existe !
                                </v-alert>
                                <v-alert class='text-caption' v-else-if="existe=='no'" dense text type="warning">
                                    no Existe !
                                </v-alert>
                                <v-alert class='text-caption' v-else dense text type="info">
                                    <span class="text-caption grey--text">Sku: 99999</span>
                                </v-alert>
                            </v-col >
                            <v-col cols="12" md="4">
                                <v-text-field class='text-caption' v-model="nombre" label="Nombre Producto" disabled filled outlined dense ></v-text-field>
                            </v-col>
                            <v-col cols="12" md="3">
                                <v-text-field class='text-caption' v-model="categoria" label="Categoria" disabled filled outlined dense ></v-text-field>
                            </v-col>
                        </v-row>
                        <v-divider class="grey"></v-divider>
                        <v-row class="mt-1">
                            <v-col cols="12" md="6">
                                <v-textarea rows="6" class='text-caption' no-resize outlined v-model="descripcion" label="Descripcion" disabled filled></v-textarea>                                
                            </v-col>
                            <v-col cols="12" md="6">
                                <v-row>
                                <v-col cols="12" md="6">
                                    <v-text-field class='text-caption' v-model="caracteristica" label="Caracteristica" outlined dense disabled filled></v-text-field>
                                    <v-select class='text-caption' v-on:change="OpenDialog(bodega)" v-model="bodega" :items="listabodega" label="Bodega" dense outlined :disabled="disabledDatos" :filled="filledDatos"></v-select>
                                </v-col>
                                <v-col cols="12" md="6">
                                    <v-text-field class='text-caption' @keypress="onlyNumber" v-model="preciolocal" label="Precio" outlined dense :disabled="disabledDatos" :filled="filledDatos"></v-text-field>
                                    <v-text-field class='text-caption' @keypress="onlyNumber" v-model="cantidad" label="Cantidad" outlined dense clearable :disabled="disabledDatos" :filled="filledDatos"></v-text-field>
                                </v-col>
                                <v-col cols="12" md="12" >
                                    <v-text-field style="margin-top:-20px" class='text-caption' v-model="observa" label="Observacion" outlined dense :disabled="disabledDatos" :filled="filledDatos"></v-text-field>
                                </v-col>
                                </v-row>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-form>
            </v-card-text>
            <v-card-actions style="margin-top:-25px">
                <v-spacer></v-spacer>
                <v-btn @click="newIngreso()" class="mr-5" small color="teal" dark rounded><v-icon small>add_circle</v-icon><span class='text-btn'>Nuevo</span></v-btn>
                <v-btn @click="saveIngreso()" class="mr-5" small color="teal" dark rounded><v-icon small>save</v-icon><span class='text-btn'>Grabar</span></v-btn>
            </v-card-actions>
        </v-card>
        <v-dialog v-model="dialog" transition="dialog-bottom-transition" max-width="600" persistent>
            <v-card>
            <v-toolbar color="primary" dark>Nueva Bodega</v-toolbar>
            <v-card-text class="mt-5">
                <v-text-field class='text-caption' v-model="newbodega" label="Nombre Bodega" :disabled="disabledBodega" :filled="filledBodega" outlined dense required></v-text-field>
                <v-row>
                    <v-col cols="12" md="6">
                        <div v-if="alertabodega!='si'">
                            <v-btn @click="newBodega()" small color="teal" dark rounded><v-icon small>save</v-icon><span class='text-btn'>Grabar</span></v-btn>
                        </div>
                        <div v-else>
                            <v-btn small color="teal" dark rounded><v-icon small>save</v-icon><span class='text-btn'>Grabar</span></v-btn>
                        </div>
                    </v-col>
                    <v-col cols="12" md="6">
                        <v-alert class='text-caption' v-if="alertabodega=='si'" dense text type="success">
                            Bodega Creada con Exito !!!
                        </v-alert>
                        <v-alert class='text-caption' v-else-if="alertabodega=='no'" dense text type="error">
                            No ha Ingresado Bodega !!!
                        </v-alert>
                        <v-alert class='text-caption' v-else dense text type="info">
                            Dato Obligatorio !!!
                        </v-alert>
                    </v-col>
                </v-row>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn text @click="CloseDialog()" color="indigo">Close</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <snack :Msg="Msg" :color="color" :msgbox="msgbox"/>
        <dialogo :loading="loading"  />        
    </div>
</template>
<script>
import snack from '@/components/snack.vue';
import dialogo from '@/components/dialogo.vue';
import axios from 'axios';
export default {
    name:'Ajuste',  
    components:{dialogo, snack}, 
    props: ['usu','contrato'], 
    data(){
        return{
            //contrato:'123',
            loading:false,
            msgbox:false,
            color:'',
            Msg:'',
            disabledBodega:false,
            filledBodega:false,
            disabledSku:false,
            filledSku:false,
            disabledDatos:true,
            filledDatos:true,
            existe:'',
            alertabodega:'',
            newbodega:'',
            dialog:false,
            listabodega:[],
            sku:'',
            nombre:'',
            categoria:'',
            ncategoria:0,
            descripcion:'',
            caracteristica:'',
            cantidad:'',
            bodega:'',
            nbodega:0,
            preciolocal:'',
            observa:''            
        }
    },
    async mounted(){
        await this.SelectBodegas()
    },
    methods:{
        restoDatos(bool){
            this.disabledDatos=bool,
            this.filledDatos=bool
        },
        onlyNumber ($event) {
         //console.log($event.keyCode); //keyCodes value
            let keyCode = ($event.keyCode ? $event.keyCode : $event.which);
            if ((keyCode < 48 || keyCode > 57)) { // && keyCode !== 46 is dot
                $event.preventDefault();
            }
        },
        showSnack(){
            setTimeout(()=>{this.msgbox=false},3000)
        },
        clsInput(){
            this.nombre='';
            this.categoria='';
            this.ncategoria=0;
            this.descripcion='';
            this.caracteristica='';
            this.cantidad='';
            this.bodega='';
            this.nbodega=0,
            this.preciolocal='';
            this.observa=''
        },
        async verSku(xSku){
             this.loading=true;
             this.msgbox=false;
             this.clsInput();
            if(xSku.trim()!=''){
                this.disabledSku=true;
                this.filledSku=true;
                const response = await axios.get('http://www.app.bodeganet.cl/ApiPHP_ClientesPWA/public/api/inv/producto/'+this.contrato+'/'+xSku);
                const datos=response.data;
                console.log(datos);
                if (datos[0].existe=='no'){
                    this.existe='no'
                }
                if (datos[0].existe=='error'){
                    this.Msg='Conexion Inestable, intentelo mas tarde'
                    this.msgbox=true;
                    this.color='red';
                    this.showSnack();
                    this.newIngreso()
                }
                if (datos[0].existe=='si'){               
                    this.existe='si';
                    this.sku=datos[0].Sku;
                    this.nombre=datos[0].Nombre;
                    this.ncategoria=datos[0].Categoria;
                    this.descripcion=datos[0].Descripcion;
                    this.caracteristica=datos[0].Caracteristica;
                    this.preciolocal='';
                    await this.nombreCategoria(this.ncategoria);
                    this.restoDatos(false);
                } 
            } else {
                this.existe=''
            }
            this.loading=false;
        },
        async nombreCategoria(numeroCategoria){
            const response = await axios.get('http://www.app.bodeganet.cl/ApiPHP_ClientesPWA/public/api/inv/categorias/'+ this.contrato);
            const datos=response.data;
            //console.log(datos);
            datos.forEach(element => {
                if(element.ID==numeroCategoria){
                    this.categoria=element.Nombre
                }
            });
        },
        async saveIngreso(){
            this.loading=true;
            this.msgbox=false;
            if (this.contrato!='' && this.cantidad!='' && this.bodega!='' && this.nbodega!=0 &&
                this.nombre!='' && this.categoria!='' && this.descripcion!='' &&
                this.caracteristica!='' && this.preciolocal!='' && this.sku!=''){
                var parametros={
                    'contrato': this.contrato,
                    'sku': this.sku,
                    'cantidad': this.cantidad,
                    'precio': this.preciolocal,
                    'signo': '0+',
                    'bodega': this.nbodega,
                    'usuario':this.usu,
                    'observa':this.observa,
                    'origen':'Ingreso',
                    'sucursal':'0'};
                const response = await axios.post('http://www.app.bodeganet.cl/ApiPHP_ClientesPWA/public/api/inv/stock',parametros);
                const datos=response.data;
                console.log(datos);
                if (datos=='si'){ 
                    this.existe='si';               
                    this.Msg='Datos del Producto Existoso !!!'
                    this.msgbox=true;
                    this.color='success';
                    this.showSnack();
                } 
                if (datos=='error'){
                    this.Msg='Conexion Inestable, intentelo mas tarde'
                    this.msgbox=true;
                    this.color='red';
                    this.showSnack();
                    this.newIngreso()
                }         
            } else {
                    this.Msg='Todos los datos obligatorios'
                    this.msgbox=true;
                    this.color='info';
                    this.showSnack();
            }
            this.loading=false;
        },
        newIngreso(){
            this.loading=true;
            this.msgbox=false;
            this.sku='';
            this.clsInput();
            this.disabledSku=false;
            this.filledSku=false;
            this.existe='';
            this.loading=false;
            this.restoDatos(true);
        },
        async SelectBodegas(){
            const response = await axios.get('http://www.app.bodeganet.cl/ApiPHP_ClientesPWA/public/api/inv/bodegas/'+ this.contrato);
            const datos=response.data;
            this.listabodega=[];
            this.listabodega.push('Nueva');
            if (datos[0].existe=='si'){
                datos.forEach(element => {
                    this.listabodega.push(element.Nombre);
                });                
            }
        },
        OpenDialog(a) {
            if(a=='Nueva'){
                this.dialog=true;
                this.alertabodega='';
                this.newbodega='';
            } else {
                this.alertabodega='';
                this.newbodega='';
                this.dialog=false;
                this.numeroBodega(a);
            }
        },
        async numeroBodega(txtbodega){
            const response = await axios.get('http://www.app.bodeganet.cl/ApiPHP_ClientesPWA/public/api/inv/bodegas/'+ this.contrato);
            const datos=response.data;
            console.log(datos);
            datos.forEach(element => {
                if(element.Nombre==txtbodega){
                    this.nbodega=element.ID
                }
            });
        },
        CloseDialog() {
           if(this.newbodega!=''){
                this.bodega=this.newbodega;
                this.numeroBodega(this.bodega);
            } else {
                this.bodega='';
            }
            this.newbodega='';
            this.alertabodega='';
            this.disabledBodega=false;
            this.filledBodega=false;
            this.dialog=false;
        },
        async newBodega(){
            if(this.newbodega!=''){
                this.disabledBodega=true;
                this.filledBodega=true;
                var parametros={
                    'contrato': this.contrato,
                    'nombre': this.newbodega};
                const response = await axios.post('http://www.app.bodeganet.cl/ApiPHP_ClientesPWA/public/api/inv/bodegas/nueva',parametros);
                const datos=response.data;
                this.listabodega.push(this.newbodega);
                this.bodega=this.newbodega;
                this.alertabodega='si';                
            } else {
                this.alertabodega='no';
            }
        }
    }
}
</script>