<template>
    <div>
        <v-progress-linear v-if="rueda==true" rounded height="2" indeterminate color="primary"></v-progress-linear>
        <v-card color="mt-1 green darken-3" dark>
            <v-card-title class="text-caption">
              <span class="text-body-2">Ganancias ({{Anio}})</span>
              <v-spacer></v-spacer>
              <v-chip color='green darken-1'>${{new Intl.NumberFormat("de-DE").format(utilidad)}}</v-chip>
            </v-card-title>
        </v-card>
    </div>
</template>
<script>
import axios from 'axios';
export default {
  name: 'UtilidadVentas',
  props:['usu','contrato'],
    data(){
    return {
      //contrato:'123',
      Anio:'',
      utilidad:0,
      rueda:true,

      //Utilidad Final
      productos:[],
      movimientos:[],
      ganancias:[]


    }
  },
  async mounted(){
    this.Utilidades();
  },
  methods:{
    async Utilidades(){
      this.rueda=true;
      this.Anio=new Date().getFullYear();      
      this.utilidad=0;
      await this.utilidad_final();
      if(this.ganancias.legth!=0){
        //filtramos por año
        const found=this.ganancias.filter(res => new Date(res.fecha+'T00:00:00').getFullYear()==new Date().getFullYear());
        if(found){
          // sumar utilidades
          found.forEach(element => {
            this.utilidad=this.utilidad+element.utilidad
          });
          console.log(found)
        }
      }
      this.rueda=false;
    },
    async utilidad_final(){
      try { 
        this.ganancias=[];
        const res = await axios.get('http://www.app.bodeganet.cl/ApiPHP_ClientesPWA/public/api/inv/movimientos/productos/utilidad/'+this.contrato);
        const dato=res.data;
        if(dato.length!=0){
          this.movimientos=dato;
          // una vez obtenidos todos los movimientos
          // buscarmos todos los SKU  
          const res1 = await axios.get('http://www.app.bodeganet.cl/ApiPHP_ClientesPWA/public/api/inv/utilidad/sku/all/'+ this.contrato);
          const dato1=res1.data;
          if(dato1.length!=0){
            this.productos=dato1;
            //await this.oneSku('LB00002');
            //comenzamos el recorrido por sku
            for (let index = 0; index < this.productos.length; index++) {
              const element = this.productos[index];
              await this.oneSku(element.Sku);
            }
          }
        };
        //console.log(this.ganancias);
      } catch (error) {
        console.log(error)
      }
    },
    async oneSku(tSku){
      var nSaldo=0;
      var nCompras=0;
      var nVentas=0;
      var nUtilidad=0;
      var nPrecio=0;
      var arraycompras=[];
      var puntero=0;
      var arrayutilidad=[];
      const arrayIN = this.movimientos.filter(res => res.Sku==tSku);
      if(arrayIN){
        arraycompras.push({
          'sku':'sku',
          'valor':'0',
          'unidades':'0'
        });
        for (let index = 0; index < arrayIN.length; index++) {
          const result = arrayIN[index];
          switch (result.Movimiento) {
            case 'Ingreso':
              nSaldo=parseFloat(nSaldo)+parseFloat(result.Cantidad);
              break;
            case 'Egreso':
              nSaldo=parseFloat(nSaldo)-parseFloat(result.Cantidad);
              break;
            case 'Reciclaje':
              nSaldo=parseFloat(nSaldo)-parseFloat(result.Cantidad);
              break;
            case 'Compra':
              // llenamos el array de compras
              arraycompras.push({
                'fecha':result.Fecha,
                'sku':result.Sku,
                'valor':result.Precio,
                'unidades':result.Cantidad
              });
              break;
            case 'Venta':
              if(parseFloat(result.Cantidad)<=parseFloat(nSaldo)){
                nVentas=parseFloat(result.Precio)*parseFloat(result.Cantidad);
                nCompras=parseFloat(nPrecio)*parseFloat(result.Cantidad);
                nUtilidad=parseFloat(nVentas)-parseFloat(nCompras);
                arrayutilidad.push({
                  'fecha':result.Fecha,
                  'sku':result.Sku,
                  'cantidad':parseFloat(result.Cantidad),
                  'compra':parseFloat(nPrecio),
                  'venta':parseFloat(result.Precio),
                  'utilidad':parseFloat(nUtilidad)
                });
                nSaldo=parseFloat(nSaldo)-parseFloat(result.Cantidad);
              } else {
                //recorrer cantidad y verificar saldo vs precio
                for (let index = 0; index < parseFloat(result.Cantidad); index++) {
                  if (parseFloat(nSaldo)<=0 && arraycompras.length>(puntero+1)){
                    puntero++;
                    nSaldo=parseFloat(nSaldo)+parseFloat(arraycompras[puntero].unidades);
                    nPrecio=parseFloat(arraycompras[puntero].valor);
                  };
                  nVentas=parseFloat(result.Precio)*1;
                  nCompras=parseFloat(nPrecio)*1;
                  nUtilidad=parseFloat(nVentas)-parseFloat(nCompras);
                  arrayutilidad.push({
                    'fecha':result.Fecha,
                    'sku':result.Sku,
                    'cantidad':1,
                    'compra':parseFloat(nPrecio),
                    'venta':parseFloat(result.Precio),
                    'utilidad':parseFloat(nUtilidad)
                  });
                  nSaldo=parseFloat(nSaldo)-1;
                };
              }
            break;
            case 'Devolucion':
              nVentas=parseFloat(result.Precio)*parseFloat(result.Cantidad);
              nCompras=parseFloat(nPrecio)*parseFloat(result.Cantidad);
              nUtilidad=parseFloat(nVentas)-parseFloat(nCompras);
              nUtilidad=nUtilidad*-1;
              arrayutilidad.push({
                'fecha':result.Fecha,
                'sku':result.Sku,
                'cantidad':parseFloat(result.Cantidad),
                'compra':parseFloat(nPrecio),
                'venta':parseFloat(result.Precio),
                'utilidad':parseFloat(nUtilidad)
              });
              nSaldo=parseFloat(nSaldo)+parseFloat(result.Cantidad);
              break;
          };
        };
      };
      this.ganancias = this.ganancias.concat(arrayutilidad);
    },

  }
}
</script>