<template>
  <div>
    <h2 class="mt-1 ml-15 hidden-sm-and-down grey--text">Credito Cobranza</h2> <!-- Full Screen -->
    <h3 class="mt-1 hidden-sm-and-up grey--text">Credito Cobranza</h3> <!-- Mobile Screen -->
    <div class="mt-5">
      <v-bottom-navigation :value="value" color="success">
        <v-btn @click="OpenComponent(0)" :disabled="comp0">
          <span>Pago Cliente</span>
          <v-icon>{{PagoIcon}}</v-icon>
        </v-btn>
        <v-btn @click="OpenComponent(1)" :disabled="comp1">
          <span>Pago Proveedor</span>
          <v-icon>{{CobroIcon}}</v-icon>
        </v-btn>
      </v-bottom-navigation>
      <div class="mt-2">
      
          <component v-bind:is="componente" :usu="usu" :contrato="contrato"></component>
      
      </div>
    </div>
    <dialogo :loading="loading"  />
  </div>
</template>

<script>
import axios from 'axios';
import dialogo from '@/components/dialogo.vue';
import PagoCliente from '@/components/PagoCliente.vue';
import PagoProveedor from '@/components/PagoProveedor.vue';
export default {
  name: 'Credito',
  components: { dialogo, PagoCliente, PagoProveedor },
  props: ['usu','contrato'],
  data(){
    return{
      loading:false,
      value:null,
      componente:'',
      IconSelect:'check_circle',
      PagoIcon:'monetization_on',
      CobroIcon:'monetization_on',
      IconDefault:'monetization_on',
      comp0:true,
      comp1:true,
    }
  },
  async mounted(){
    await this.entrada();
  },
   methods:{
    async entrada(){
      this.loading=true;
      const response = await axios.get('http://www.app.bodeganet.cl/ApiPHP_ClientesPWA/public/api/inv/usuarios/'+ this.contrato +'/'+ this.usu);
      const datos=response.data;
      if (datos[0].existe=='si'){
        if(datos[0].Permiso_8_1!=0) {
          this.comp0=false;
        };
        if(datos[0].Permiso_8_2!=0) {
          this.comp1=false
        };
      } 
      this.moduloActivo();      
    },
    moduloActivo(){
        if (this.comp0!=true){
            this.componente='PagoCliente';
            this.PagoIcon=this.IconSelect;
            this.CobroIcon=this.IconDefault;
            this.value=0
        } else {
            if (this.comp1!=true){
                this.componente='PagoProveedor';
                this.PagoIcon=this.IconDefault;
                this.CobroIcon=this.IconSelect;
                this.value=1
            } else {
                this.PagoIcon=this.IconDefault;
                this.CobroIcon=this.IconDefault;
            }
        }
        this.loading=false;
    },
    OpenComponent(value){
      let vComponente;
      switch (value) {
        case 0:
          vComponente='PagoCliente';
          this.PagoIcon=this.IconSelect;
          this.CobroIcon=this.IconDefault;
        break;
        case 1:
          vComponente='PagoProveedor';
          this.PagoIcon=this.IconDefault;
          this.CobroIcon=this.IconSelect;
        break;
      }
      this.componente=vComponente;
    }
  }
}
</script>