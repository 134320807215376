<template>
    <div>
        <h3>Utilidad Mensual ({{Mes}}-{{Anio}})
          <v-progress-linear v-if="rueda===true" rounded height="2" indeterminate color="primary"></v-progress-linear>
        </h3>
        <v-divider></v-divider>
        <div class="mt-1">
        <v-data-table :headers="headerVentas" :items="itemVentas" class="elevation-1" hide-default-footer dark>
          <template v-slot:item.tPromedio="{ item }">
            <v-chip color="indigo" dark> {{ item.tPromedio }}</v-chip>
          </template>
        </v-data-table>
        </div>
        <div class="mt-2">
          <v-progress-linear v-if="rueda==true" rounded height="2" indeterminate color="primary"></v-progress-linear>
          <v-card color="mt-1 green darken-3" dark>
              <v-card-title class="text-caption">
                <span class="text-body-2">Ganancias ({{Anio}})</span>
                <v-spacer></v-spacer>
                <v-chip color='green darken-1'>${{new Intl.NumberFormat("de-DE").format(varUtilidadAnio)}}</v-chip>
              </v-card-title>
          </v-card>
        </div>
    </div>
</template>
<script>
import axios from 'axios';
export default {
  name: 'UtilidadMes',
  props:['usu','contrato'],
  data(){
    return {
      //contrato:'123',
      varUtilidadCompras:0,
      varUtilidadVentas:0,
      varUtilidadMes:0,
      varUtilidadAnio:0,
      varPromedio:0,
      //varResto:0,
      Mes:'',
      Anio:'',
      headerVentas:[
        {text: 'Compras ($)',align: 'center',sortable: false, value: 'tCompras'},
        {text: 'Ventas ($)',align: 'center',sortable: false, value: 'tVentas'},
        {text: 'Utilidad ($)',align: 'center',sortable: false, value: 'tUtilidad'},
        {text: 'Promedio Mes ($)',align: 'center',sortable: false, value: 'tPromedio'}
      ],
      itemVentas: [],
      rueda:true,

      //Utilidad Final
      productos:[],
      movimientos:[],
      ganancias:[]

    }
  },
  async mounted(){
    this.Utilidades();
    // this.UtilidadMensual();   
  },
  methods:{
    async Utilidades(){
      this.rueda=true;
      this.Anio=new Date().getFullYear();
      const mes=new Date().getMonth()+1;
      switch (mes) {
        case 1:
          this.Mes='Ene';
          break;
        case 2:
          this.Mes='Feb';
          break;
        case 3:
          this.Mes='Mar';
          break;
        case 4:
          this.Mes='Abr';
          break;
        case 5:
          this.Mes='May';
          break;
        case 6:
          this.Mes='Jun';
          break;
        case 7:
          this.Mes='Jul';
          break;
        case 8:
          this.Mes='Ago';
          break;
        case 9:
          this.Mes='Sep';
          break;
        case 10:
          this.Mes='Oct';
          break;
        case 11:
          this.Mes='Nov';
          break;
        case 12:
          this.Mes='Dic';
          break;
      }
      this.utilidad=0;
      await this.utilidad_final();
      if(this.ganancias.legth!=0){
        // utilidad Mensual
        const foundMes=this.ganancias.filter(
            res => new Date(res.fecha+'T00:00:00').getMonth()==new Date().getMonth() &&
                   new Date(res.fecha+'T00:00:00').getFullYear()==new Date().getFullYear()
        );
        if(foundMes){
          // sumar utilidades
          foundMes.forEach(element => {
            this.varUtilidadCompras=parseFloat(this.varUtilidadCompras)+(parseFloat(element.compra)*parseFloat(element.cantidad));
            this.varUtilidadVentas=parseFloat(this.varUtilidadVentas)+(parseFloat(element.venta)*parseFloat(element.cantidad));
            this.varUtilidadMes=parseFloat(this.varUtilidadMes)+parseFloat(element.utilidad);
          });
          // calcular Promedio
          const varPromedio= Math.round(this.varUtilidadMes/12);
          this.itemVentas=[];
          this.itemVentas.push({
            tCompras:new Intl.NumberFormat("de-DE").format(this.varUtilidadCompras),
            tVentas:new Intl.NumberFormat("de-DE").format(this.varUtilidadVentas),
            tUtilidad:new Intl.NumberFormat("de-DE").format(this.varUtilidadMes),
            tPromedio:new Intl.NumberFormat("de-DE").format(varPromedio)
          });
        };
        // utilidad anual
        const foundAnio=this.ganancias.filter(
            res1 => new Date(res1.fecha).getFullYear()==new Date().getFullYear()
        );
        if(foundAnio){
          // sumar utilidades
          foundAnio.forEach(element => {
            this.varUtilidadAnio=parseFloat(this.varUtilidadAnio)+parseFloat(element.utilidad)
          });
        }
      }
      this.rueda=false;
    },
    async utilidad_final(){
      try { 
        this.ganancias=[];
        const res = await axios.get('http://www.app.bodeganet.cl/ApiPHP_ClientesPWA/public/api/inv/movimientos/productos/utilidad/'+this.contrato);
        const dato=res.data;
        if(dato.length!=0){
          this.movimientos=dato;
          // una vez obtenidos todos los movimientos
          // buscarmos todos los SKU  
          const res1 = await axios.get('http://www.app.bodeganet.cl/ApiPHP_ClientesPWA/public/api/inv/utilidad/sku/all/'+ this.contrato);
          const dato1=res1.data;
          if(dato1.length!=0){
            this.productos=dato1;
            //await this.oneSku('LB00002');
            //comenzamos el recorrido por sku
            for (let index = 0; index < this.productos.length; index++) {
              const element = this.productos[index];
              await this.oneSku(element.Sku);
            }
          }
        };
        //console.log(this.ganancias);
      } catch (error) {
        console.log(error)
      }
    },
    async oneSku(tSku){
      var nSaldo=0;
      var nCompras=0;
      var nVentas=0;
      var nUtilidad=0;
      var nPrecio=0;
      var arraycompras=[];
      var puntero=0;
      var arrayutilidad=[];
      const arrayIN = this.movimientos.filter(res => res.Sku==tSku);
      if(arrayIN){
        arraycompras.push({
          'sku':'sku',
          'valor':'0',
          'unidades':'0'
        });
        for (let index = 0; index < arrayIN.length; index++) {
          const result = arrayIN[index];
          switch (result.Movimiento) {
            case 'Ingreso':
              nSaldo=parseFloat(nSaldo)+parseFloat(result.Cantidad);
              break;
            case 'Egreso':
              nSaldo=parseFloat(nSaldo)-parseFloat(result.Cantidad);
              break;
            case 'Reciclaje':
              nSaldo=parseFloat(nSaldo)-parseFloat(result.Cantidad);
              break;
            case 'Compra':
              // llenamos el array de compras
              arraycompras.push({
                'fecha':result.Fecha,
                'sku':result.Sku,
                'valor':result.Precio,
                'unidades':result.Cantidad
              });
              break;
            case 'Venta':
              if(parseFloat(result.Cantidad)<=parseFloat(nSaldo)){
                nVentas=parseFloat(result.Precio)*parseFloat(result.Cantidad);
                nCompras=parseFloat(nPrecio)*parseFloat(result.Cantidad);
                nUtilidad=parseFloat(nVentas)-parseFloat(nCompras);
                arrayutilidad.push({
                  'fecha':result.Fecha,
                  'sku':result.Sku,
                  'cantidad':parseFloat(result.Cantidad),
                  'compra':parseFloat(nPrecio),
                  'venta':parseFloat(result.Precio),
                  'utilidad':parseFloat(nUtilidad)
                });
                nSaldo=parseFloat(nSaldo)-parseFloat(result.Cantidad);
              } else {
                //recorrer cantidad y verificar saldo vs precio
                for (let index = 0; index < parseFloat(result.Cantidad); index++) {
                  if (parseFloat(nSaldo)<=0 && arraycompras.length>(puntero+1)){
                    puntero++;
                    nSaldo=parseFloat(nSaldo)+parseFloat(arraycompras[puntero].unidades);
                    nPrecio=parseFloat(arraycompras[puntero].valor);
                  };
                  nVentas=parseFloat(result.Precio)*1;
                  nCompras=parseFloat(nPrecio)*1;
                  nUtilidad=parseFloat(nVentas)-parseFloat(nCompras);
                  arrayutilidad.push({
                    'fecha':result.Fecha,
                    'sku':result.Sku,
                    'cantidad':1,
                    'compra':parseFloat(nPrecio),
                    'venta':parseFloat(result.Precio),
                    'utilidad':parseFloat(nUtilidad)
                  });
                  nSaldo=parseFloat(nSaldo)-1;
                };
              }
            break;
            case 'Devolucion':
              nVentas=parseFloat(result.Precio)*parseFloat(result.Cantidad);
              nCompras=parseFloat(nPrecio)*parseFloat(result.Cantidad);
              nUtilidad=parseFloat(nVentas)-parseFloat(nCompras);
              nUtilidad=nUtilidad*-1;
              arrayutilidad.push({
                'fecha':result.Fecha,
                'sku':result.Sku,
                'cantidad':parseFloat(result.Cantidad),
                'compra':parseFloat(nPrecio),
                'venta':parseFloat(result.Precio),
                'utilidad':parseFloat(nUtilidad)
              });
              nSaldo=parseFloat(nSaldo)+parseFloat(result.Cantidad);
              break;
          };
        };
      };
      this.ganancias = this.ganancias.concat(arrayutilidad);
    },





    // async UtilidadMensual(){
    //   this.rueda=true;
    //   const fecha=new Date();
    //   const tFecha = fecha.toISOString();
    //   const hoy=tFecha.substring(0,10);
    //   const anio=tFecha.substring(0,4);
    //   const mes=tFecha.substring(6,7);

    //   switch (mes) {
    //     case '1':
    //       this.Mes='Ene';
    //       break;
    //     case '2':
    //       this.Mes='Feb';
    //       break;
    //     case '3':
    //       this.Mes='Mar';
    //       break;
    //     case '4':
    //       this.Mes='Abr';
    //       break;
    //     case '5':
    //       this.Mes='May';
    //       break;
    //     case '6':
    //       this.Mes='Jun';
    //       break;
    //     case '7':
    //       this.Mes='Jul';
    //       break;
    //     case '8':
    //       this.Mes='Ago';
    //       break;
    //     case '9':
    //       this.Mes='Sep';
    //       break;
    //     case '10':
    //       this.Mes='Oct';
    //       break;
    //     case '11':
    //       this.Mes='Nov';
    //       break;
    //     case '12':
    //       this.Mes='Dic';
    //       break;
    //   }
    //   this.Anio=anio;
    //   await this.Util(anio,mes);
    //   this.rueda=false;
    // },
    // // async Util(anio,mes){
    //   try {
    //     // obtenemos todos los sku
    //     const response = await axios.get('http://www.app.bodeganet.cl/ApiPHP_ClientesPWA/public/api/inv/utilidad/sku/all/'+ this.contrato);
    //     const datos=response.data;
    //     if(datos.length!=0){
    //       this.codigos=datos;
    //       //console.log(this.codigos);
    //       // obtenemos todos las entradas
    //       const response1 = await axios.get('http://www.app.bodeganet.cl/ApiPHP_ClientesPWA/public/api/inv/utilidad/entradas/'+ this.contrato);
    //       const datos1=response1.data;
    //       if(datos1.length!=0){
    //           this.entradas=datos1;
    //           //console.log(this.entradas);
    //       };
    //       // obtenemos las salidas
    //       const response2 = await axios.get('http://www.app.bodeganet.cl/ApiPHP_ClientesPWA/public/api/inv/utilidad/salidas/'+ this.contrato);
    //       const datos2=response2.data;
    //       if(datos2.length!=0){
    //           this.salidas=datos2;
    //           //console.log(this.salidas);
    //       };
    //       let HoraInicio = new Date();
    //       console.log('INICIO');
    //       console.log(HoraInicio);
    //       //comenzamos el recorrido por sku
    //       let arraySalida=[];
    //       this.codigos.forEach(element => {
    //         const nSku=element.Sku;
    //         //rescatamos las entradas
    //         const arrayIN = this.entradas.filter(res => res.Sku==nSku);
    //         if(arrayIN){
    //           // preparamos la entrada
    //           let ini=0;
    //           let fin=0;
    //           let valor=0;
    //           let arrayEntrada=[];
    //           for (let index = 0; index < arrayIN.length; index++) {
    //               const res = arrayIN[index];
    //               ini=fin+1;
    //               fin=fin+parseFloat(res.Cantidad);
    //               valor=res.Precio;
    //               arrayEntrada.push({
    //                   'sku':res.Sku,
    //                   'inicio':parseFloat(ini),
    //                   'final':parseFloat(fin),
    //                   'precio':parseFloat(valor)
    //               });                
    //           };
    //           //console.log(arrayEntrada);
    //           // recorremos las Salidas
    //           const arrayOUT = this.salidas.filter(res => res.Sku==nSku);
    //           if(arrayOUT){
    //             let fec='';
    //             let rango=0;
    //             let precio_venta=0;
    //             let precio_compra=0;
    //             let utilidad=0;
    //             for (let index = 0; index < arrayOUT.length; index++) {
    //               const res1 = arrayOUT[index];
    //               fec=res1.Fecha;
    //               if(res1.Origen=='Venta' || res1.Origen=='Devolucion'){
    //                 precio_venta=parseFloat(res1.Precio);
    //                 for (let index = 0; index < res1.Cantidad; index++) {  
    //                   if(res1.Origen=='Venta'){
    //                       rango=parseInt(rango)+1;
    //                       //buscar precio compra segun rango
    //                       const found = arrayEntrada.find(res => rango>=res.inicio && rango<=res.final);
    //                       if(found){ precio_compra=parseFloat(found.precio) };
    //                       utilidad=parseFloat(precio_venta)-parseFloat(precio_compra);
    //                   };
    //                   if(res1.Origen=='Devolucion'){
    //                       rango=parseInt(rango)-1;
    //                       //buscar precio compra segun rango
    //                       const found = arrayEntrada.find(res => rango>=res.inicio && rango<=res.final);
    //                       if(found){ precio_compra=parseFloat(found.precio) };
    //                       utilidad=parseFloat(precio_compra)-parseFloat(precio_venta);
    //                       // cambiar signo por devolucion
    //                       precio_venta=parseFloat(precio_venta)*-1
    //                   }             
    //                   //array utilidad
    //                   arraySalida.push({
    //                       'Fecha':fec,
    //                       'Sku': res1.Sku,
    //                       'PrecioCompra':precio_compra,
    //                       'PrecioVenta':precio_venta,
    //                       'Utilidad':utilidad,
    //                       'Origen':res1.Origen
    //                   })
    //                 }
    //               };
    //               if(res1.Origen=='Egreso'){
    //                 rango=parseInt(rango)-parseFloat(res1.Cantidad);
    //               }            
    //             }
    //           }
    //         }
    //       });
    //       // console.log(arrayEntrada);
    //       // console.log(arraySalida);
    //       // filtrar la salida segun lo necesitado
    //       let filtro = arraySalida.filter(res => parseInt(new Date(res.Fecha+'T00:00:00').getFullYear())==parseInt(anio) &&  parseInt(new Date(res.Fecha+'T00:00:00').getMonth()+1)==parseInt(mes));
    //       filtro.forEach(result => {
    //         this.varCompras=this.varCompras+result.PrecioCompra;
    //         this.varVentas=this.varVentas+result.PrecioVenta;
    //         this.varUtilidad=this.varUtilidad+result.Utilidad;
    //       });
    //       this.varPromedio= Math.round(this.varUtilidad/12);
    //       this.itemVentas=[];
    //       this.itemVentas.push({
    //         tCompras:new Intl.NumberFormat("de-DE").format(this.varCompras),
    //         tVentas:new Intl.NumberFormat("de-DE").format(this.varVentas),
    //         tUtilidad:new Intl.NumberFormat("de-DE").format(this.varUtilidad),
    //         tPromedio:new Intl.NumberFormat("de-DE").format(this.varPromedio)
    //       });
    //       console.log('FIN');
    //       let now = new Date();
    //       console.log(now);
    //     };                
    //   } catch (error) {
    //       console.log(error)
    //   }
    // },

  }
}
</script>