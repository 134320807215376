<template>
  <div>
    <v-card>
        <v-card-text>
            <v-data-table :loading="progress" loading-text="Cargando....." dark :search="likesearch" :headers="headers" :items="desserts" :page.sync="page" :items-per-page="itemsPerPage" hide-default-footer class="elevation-1 text-size" @page-count="pageCount = $event">
                <template v-slot:item.tStock="{ item }">
                    <v-chip small :color="CriticalSearch(item.tSku,item.tBodega)" dark>
                        {{ item.tStock }}
                    </v-chip>                  
                    <!-- <v-chip small :color="getColor(item.numStock,item.numCritico)" dark>
                        {{ item.tStock }}
                    </v-chip> -->
                </template>
                <template v-slot:item.ruta="{ item }">
                  <a target="_blank" :href="item.ruta"><v-icon class="white--text" small >photo_library</v-icon></a>
                </template>
            </v-data-table>
        </v-card-text>
        <v-card-actions>
          <v-container>
            <v-row>
                <v-col cols="12" md="8">
                    <v-pagination v-model="page" :length="pageCount"></v-pagination>
                </v-col>
                <v-col cols="10" md="4">
                    <v-text-field class='ml-5 mr-2 text-caption' v-model="likesearch" label="Buscar Producto" dense required></v-text-field>
                </v-col>
                <v-col cols="2" md="2">
                    <!-- <v-btn @click="verSku(sku)" class="mt-1" small color="teal" dark rounded><v-icon small>search</v-icon></v-btn> -->
                </v-col>
            </v-row>
          </v-container>
        </v-card-actions>
    </v-card>
  </div>
</template>
<style scoped>
.text-size >>> td {
    font-size: 9pt !important;
    height: 33px !important;
    /* background-color: lightyellow; */
}
</style>
<script>
import axios from 'axios';
  export default {
    name:'Stock',
    props: ['usu','contrato'],
    data () {
      return {
        //contrato:'123',
        likesearch:'',
        page: 1,
        pageCount: 0,
        itemsPerPage: 8,
        progress:false,        
        headers: [
          { text: 'Sku', align: 'start', sortable: true, value: 'tSku' },
          { text: 'Barra', align: 'start', sortable: true, value: 'tBarra' },
          { text: 'Producto', align: 'start', sortable: true, value: 'tNombre' },
          { text: 'Bodega', align: 'start', sortable: true, value: 'tBodega' },
          { text: 'Stock', align: 'center', sortable: true, value: 'tStock' },
          { text: '($) Detalle', align: 'center', sortable: true, value: 'tDetalle' },
          { text: '($) x Mayor', align: 'center', sortable: true, value: 'tMayor' },
          { text: '($) Oferta', align: 'center', sortable: true, value: 'tOferta' },
          { text: '(%) Stock', align: 'center', sortable: true, value: 'tPorcentaje' },
          { text: 'Ver', align: 'start', sortable: false, value: 'ruta' },
        ],
        desserts: [], 
        SkuCritical:[],     
      }
    },
    mounted(){
      
      this.generaTabla()
      this.allCritical()
      
    },
    methods: {
      async generaTabla(){
        this.progress=true;
        const response = await axios.get('http://www.app.bodeganet.cl/ApiPHP_ClientesPWA/public/api/inv/stock/datatable/'+this.contrato);
        const datos=response.data;
        //console.log(datos);
        this.desserts=[];
        if (datos[0].existe=='si'){
          datos.forEach(element => {
            let html='http://www.app.bodeganet.cl/ApiPHP_ClientesPWA/public/api/inv/producto/integracion/web/'+this.contrato+'/'+element.Sku;
            this.desserts.push({
              tSku: element.Sku,
              tNombre: element.Nombre,
              tBarra: element.Barra,
              tBodega: element.Bodega,
              tStock: new Intl.NumberFormat("de-DE").format(parseFloat(element.Total)),
              tDetalle: new Intl.NumberFormat("de-DE").format(parseFloat(element.PrecioLocal)),
              tMayor: new Intl.NumberFormat("de-DE").format(parseFloat(element.PrecioMayor)),
              tOferta: new Intl.NumberFormat("de-DE").format(parseFloat(element.PrecioOferta)),
              tPorcentaje: new Intl.NumberFormat("de-DE").format(parseFloat(element.Porcentaje))+'%',
              numCritico: element.Critico,
              numStock: element.Total,
              ruta:html
            })
          });
        }
        this.progress=false;
      },
      async allCritical(){
        this.progress=true;
        this.SkuCritical=[];
        const response = await axios.get('http://www.app.bodeganet.cl/ApiPHP_ClientesPWA/public/api/inv/report/calculostock/'+this.contrato)
        const datos=response.data;
        if (datos[0].existe=='si'){
            datos.forEach(element => {
              this.SkuCritical.push({
                cSku: element.Sku,
                cBodega: element.Bodega,
                cStock: element.Stock,
                cP3M: element.P3M,
                cCritico:element.Critico
              })
            });
        }
        this.progress=false;
      },
      CriticalSearch(tSku,tBodega){
        //console.log(tSku,tBodega);
        const resultado = this.SkuCritical.find( arreglo => arreglo.cSku === tSku && arreglo.cBodega === tBodega);
        if (resultado){
          if (resultado.cCritico!=0){ return 'red' }
          else { 
            if ((resultado.cStock/2) < resultado.cP3M) { return 'orange' }
            else { return 'green' }
          }
        } 
      },
    },
  }
</script>