<template>
    <div>
        <v-card >
            <v-card-title class="blue-grey white--text">PROVEEDOR</v-card-title>
            <v-card-subtitle class="blue-grey white--text">Ficha</v-card-subtitle>
            <v-card-text>
                <v-form>
                    <v-container>
                        <v-row>
                            <v-col cols="8" md="2">
                                <v-text-field class='text-caption' v-model="rut" :counter="15" label="Rut" outlined dense :disabled="disabledRut" :filled="filledRut" required></v-text-field>
                            </v-col>
                            <v-col cols="4" md="1"><v-btn @click="enviarRut(rut)" class="mt-1" small color="teal" dark rounded><v-icon small>search</v-icon></v-btn></v-col>
                            <v-col cols="12" md="3">
                                <v-alert class='text-caption' v-if="existe=='si'" dense text type="success">
                                    Existe !
                                </v-alert>
                                <v-alert class='text-caption' v-else-if="existe=='no'" dense text type="warning">
                                    Nuevo !
                                </v-alert>
                                <v-alert class='text-caption' v-else dense text type="info">
                                    <span class="text-caption grey--text">>>> Rut: 99999999-X</span>
                                </v-alert>
                            </v-col >
                            <v-col cols="12" md="6">
                                <v-text-field class='text-caption' v-model="razon" :counter="60" label="Razon Social" outlined dense clearable :disabled="disabledDatos" :filled="filledDatos"></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12" md="3">
                                <v-text-field class='text-caption' v-model="direccion" :counter="50" label="Direccion" outlined dense clearable :disabled="disabledDatos" :filled="filledDatos"></v-text-field>
                            </v-col>
                            <v-col cols="12" md="3">
                                <v-text-field class='text-caption' v-model="comuna" :counter="20" label="Comuna" outlined dense clearable :disabled="disabledDatos" :filled="filledDatos"></v-text-field>
                            </v-col>
                            <v-col cols="12" md="3">
                                <v-text-field class='text-caption' v-model="ciudad" :counter="20" label="Ciudad" outlined dense clearable :disabled="disabledDatos" :filled="filledDatos"></v-text-field>
                            </v-col>                            
                            <v-col cols="12" md="3">
                                <v-text-field class='text-caption' v-model="email" :counter="30" label="E-mail" outlined dense clearable :disabled="disabledDatos" :filled="filledDatos"></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12" md="3">
                                <v-text-field class='text-caption' v-model="telefono" :counter="25" label="Telefono" outlined dense clearable :disabled="disabledDatos" :filled="filledDatos"></v-text-field>
                            </v-col>
                            <v-col cols="12" md="6">
                                <v-text-field class='text-caption' v-model="observacion" :counter="100" label="Giro" outlined dense clearable :disabled="disabledDatos" :filled="filledDatos"></v-text-field>
                            </v-col>
                            <v-col cols="6" md="2">
                                <v-select class='text-caption' v-model="estado" :items="estados" label="Estado" dense outlined :disabled="disabledDatos" :filled="filledDatos"></v-select>
                            </v-col> 
                        </v-row>
                    </v-container>
                </v-form>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn  @click="newProveedor()" class="mr-5" small color="teal" dark rounded><v-icon small>add_circle</v-icon><span class='text-btn'>Nuevo</span></v-btn>
                <v-btn  @click="saveProveedor()" class="mr-5" small color="teal" dark rounded><v-icon small>save</v-icon><span class='text-btn'>Grabar</span></v-btn>
            </v-card-actions>
        </v-card>
        <snack :Msg="Msg" :color="color" :msgbox="msgbox"/>
        <dialogo :loading="loading"  />
    </div>
</template>
<script>
import snack from '@/components/snack.vue';
import dialogo from '@/components/dialogo.vue';
import axios from 'axios';
export default {
    name:'Proveedor',
    components:{dialogo, snack},
    props:['usu','contrato'],
    data(){
        return{
            //contrato:'123',
            disabledRut:false,
            filledRut:false,
            disabledDatos:true,
            filledDatos:true,
            loading:false,
            msgbox:false,
            color:'',
            Msg:'',
            existe:'',            
            rut: '',
            razon: '',
            direccion:'',
            comuna:'',
            ciudad:'',
            email: '',
            telefono:'',
            observacion:'',
            estados:['V','N'],
            estado:'V'
        }
    },
    methods:{
        enviarRut(rutCompleto){
            let ruts=this.validaRut(rutCompleto);
            if (ruts){
                this.verProveedor(rutCompleto)
            } else {
                this.Msg='Rut no Valido'
                this.msgbox=true;
                this.color='red';
                this.showSnack();
            }
        },
        validaRut(rutCompleto){
            if (!/^[0-9]+[-|‐]{1}[0-9kK]{1}$/.test( rutCompleto ))
			    return false;
		    var tmp 	= rutCompleto.split('-');
		    var digv	= tmp[1]; 
		    var ruti 	= tmp[0];
		    if ( digv == 'K' ) digv = 'k' ;
		    return (this.dv(ruti) == digv );
        },
        dv(T){
            var M=0,S=1;
    		for(;T;T=Math.floor(T/10))
	    		S=(S+T%10*(9-M++%6))%11;
		    return S?S-1:'k';
        },
        restoDatos(bool){
            this.disabledDatos=bool,
            this.filledDatos=bool
        },
        showSnack(){
            setTimeout(()=>{this.msgbox=false},3000)
        },
        clsInput(){
            this.razon='';
            this.direccion='';
            this.ciudad='';
            this.comuna='';
            this.telefono='';
            this.email='';
            this.observacion='';
            this.estado='V';
        },
        async verProveedor(xRut){
            this.loading=true;
            this.msgbox=false;
            this.clsInput();
            if(xRut.trim()!=''){
                this.disabledRut=true;
                this.filledRut=true;
                const response = await axios.get('http://www.app.bodeganet.cl/ApiPHP_ClientesPWA/public/api/inv/proveedor/'+this.contrato+'/'+ xRut);
                const datos=response.data;
                console.log(datos);
                if (datos[0].existe=='no'){
                    this.existe='no'
                    this.restoDatos(false);
                }
                if (datos[0].existe=='error'){
                    this.Msg='Conexion Inestable, intentelo mas tarde'
                    this.msgbox=true;
                    this.color='red';
                    this.showSnack();
                    this.newProveedor()

                }
                if (datos[0].existe=='si'){               
                    this.existe='si';
                    this.rut=datos[0].Rut;
                    this.razon=datos[0].Razon;
                    this.direccion=datos[0].Direccion;
                    this.ciudad=datos[0].Ciudad;
                    this.comuna=datos[0].Comuna;
                    this.telefono=datos[0].Telefono;
                    this.email=datos[0].Email;
                    this.observacion=datos[0].Observacion;
                    this.estado=datos[0].Estado;
                    this.restoDatos(false);
                } 
            } else {
                this.existe=''
            }
            this.loading=false;
        },
        async saveProveedor(){
            this.loading=true;
            this.msgbox=false;
            if (this.contrato!='' &&
                this.rut!='' && this.razon!=''){
                var parametros={
                    'contrato': this.contrato,
                    'rut': this.rut,
                    'razon': this.razon,
                    'direccion': this.direccion,
                    'ciudad': this.ciudad,
                    'comuna': this.comuna,
                    'telefono': this.telefono,
                    'email': this.email,
                    'observacion': this.observacion,
                    'estado': this.estado};
                if (this.existe=='si'){
                    // actualizar
                    const response = await axios.put('http://www.app.bodeganet.cl/ApiPHP_ClientesPWA/public/api/inv/proveedor/actualiza/'+this.rut,parametros);
                    const datos=response.data;
                    console.log(datos);
                    if (datos=='si'){                
                        this.Msg='Modificacion de Proveedor Existoso !!!'
                        this.msgbox=true;
                        this.color='success';
                        this.showSnack();
                    } 
                    if (datos=='error'){
                        this.Msg='Conexion Inestable, intentelo mas tarde'
                        this.msgbox=true;
                        this.color='red';
                        this.showSnack();
                        this.newProveedor()
                    }
                }
                if (this.existe=='no'){
                    // ingresar                    
                    const response = await axios.post('http://www.app.bodeganet.cl/ApiPHP_ClientesPWA/public/api/inv/proveedor/nuevo',parametros);
                    const datos=response.data;
                    console.log(datos);
                    if (datos=='si'){ 
                        this.existe='si';               
                        this.Msg='Nuevo Proveedor Existoso !!!'
                        this.msgbox=true;
                        this.color='success';
                        this.showSnack();
                    } 
                    if (datos=='error'){
                        this.Msg='Conexion Inestable, intentelo mas tarde'
                        this.msgbox=true;
                        this.color='red';
                        this.showSnack();
                        this.newProveedor()
                    }
                }
                // if (this.existe==''){
                //     this.Msg='Tiene que validar Rut'
                //     this.msgbox=true;
                //     this.color='red'
                // }            
            } else {
                    this.Msg='Rut y Razon obligatorios'
                    this.msgbox=true;
                    this.color='info';
                    this.showSnack();
            }
            this.loading=false;
        },
        newProveedor(){
            this.loading=true;
            this.msgbox=false;
            this.rut='';
            this.clsInput();
            this.disabledRut=false;
            this.filledRut=false;
            this.existe='';
            this.loading=false;
            this.restoDatos(true);
        },
    }
}
</script>