<template>
    <div>
        <GChart type="ColumnChart" :data="chartData" :options="chartOptions"/>
    </div>
</template>

<script>
import axios from 'axios';
import { GChart } from 'vue-google-charts'
export default {
  name: 'DashCalidad',
  components: { GChart },
  props: ['usu','contrato'],
  data(){
    return {
      menor1:0,
      menor2:0,
      menor3:0,
      mayor1:0,
      mayor2:0,
      mayor3:0,
      chartData: [],
      chartOptions: {
          title: 'Calidad  :: Tiempos de Respuestas',
          legend: { position: 'bottom' }
      }
  }},
  mounted(){
    this.grafico()
  },
  methods:{
    async grafico(){
      const response = await axios.get('http://www.app.bodeganet.cl/ApiPHP_ClientesPWA/public/api/inv/dash/grafico/punto/venta/'+this.contrato);
      const datos=response.data;
      if (datos[0].existe=='si'){ 
      //console.log(datos);
        datos.forEach(element => {
          if (element.punto=='Local'){ 
            this.menor1=element.Menor;
            this.mayor1=element.Mayor
          }
          if (element.punto=='Web'){ 
            this.menor2=element.Menor;
            this.mayor2=element.Mayor
          }
          if (element.punto=='Comercio'){ 
            this.menor3=element.Menor;
            this.mayor3=element.Mayor
          }
        });
        const estadistica=[
          ['Respuestas','Local', 'web','Comercio'],
          ['< 5 Dias', parseInt(this.menor1),parseInt(this.menor2),parseInt(this.menor3)],
          ['> 5 Dias', parseInt(this.mayor1),parseInt(this.mayor2),parseInt(this.mayor3)],
        ];
        this.chartData=estadistica;
      }
    }
  }
}
</script>