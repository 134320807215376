<template>
    <div>
        <v-card>
            <v-card-title class="blue-grey white--text">
                PUBLICAR
            </v-card-title>
            <v-card-text>
                <v-form>
                    <v-container>
                        <v-row>
                            <v-col cols="8" md="2">
                                <v-text-field class='text-caption' v-model="sku" :counter="15" label="SKU" :disabled="disabledSku" :filled="filledSku" outlined dense required></v-text-field>
                            </v-col>
                            <v-col cols="4" md="1">
                                <v-btn v-if="btn===true" @click="lookSku(sku)" class="mt-1" small color="teal" dark rounded><v-icon small>search</v-icon></v-btn>
                                <v-btn v-else class="mt-1" small color="teal" dark rounded><v-icon small>search</v-icon></v-btn>
                            </v-col>
                            <v-col cols="12" md="2">
                                <v-alert class='text-caption' dense text type="info">
                                    <span class="text-caption grey--text">Sku: 99999</span>
                                </v-alert>
                            </v-col >
                            <v-col cols="12" md="4">
                                <v-text-field class='text-caption' v-model="nombre" label="Nombre Producto" dense outlined disabled filled></v-text-field>
                            </v-col>
                            <v-col cols="12" md="3">
                                <v-text-field class='text-caption' v-model="categoria" label="Categoria" dense outlined disabled filled></v-text-field>
                            </v-col>
                        </v-row>
                        <v-divider></v-divider>
                        <v-row>
                            <v-col cols="12" md="3" class="mt-1">
                                <h5>Datos Obligatorios</h5>
                                <v-select @change="attrSku(seccion)" class='mt-2 text-caption' v-model="seccion" :items="items" label="Seccion" item-text="name" item-value="id" dense outlined></v-select>
                                <v-select class='text-caption' v-model="condicion" :items="itemsCondicion" label="Condicion" item-text="tp" item-value="con" dense outlined></v-select>
                                <v-row>
                                    <v-col cols="12" md="6" class="mt-3"><span class="text-caption grey--text">Stock Total: {{totalStock}}</span></v-col>
                                    <v-col cols="12" md="6"><v-text-field class='text-caption' @keypress="onlyNumber" v-model="cantidad" label="Cantidad" dense outlined></v-text-field></v-col>
                                </v-row>                                
                            </v-col>
                            <v-col cols="12" md="6" class="mt-2">
                                <v-data-table :headers="headers" :items="variantes" :page.sync="page" :items-per-page="itemsPerPage" hide-default-footer class="elevation-1 text-size col-color" @page-count="pageCount = $event">
                                    <template v-slot:item.accion="{ item }">
                                        <v-icon small @click="editar(item)">edit</v-icon>
                                    </template>                                
                                </v-data-table>
                                <v-pagination v-model="page" :length="pageCount"></v-pagination>
                            </v-col>
                            <v-col cols="12" md="3" class="mt-2">
                                <v-select v-on:change="selectPrecio(miprecio)" class='text-caption' v-model="miprecio" :items="itemsPrecio" label="Tipo Precio" item-text="tipo" item-value="monto" dense outlined></v-select>
                                <v-alert class='text-caption' dense text border="left" color="grey">Neto ($):{{neto.toLocaleString()}}</v-alert> 
                                <v-alert class='text-caption' dense text border="left" color="grey">Iva ({{impuesto}}%): {{iva.toLocaleString()}}</v-alert>
                                <v-alert class='text-caption' dense text border="left" color="red">Total ($): {{total.toLocaleString()}}</v-alert>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-form>
            </v-card-text>
            <v-card-actions style="margin-top:-30px">
                <v-spacer></v-spacer>
                <v-btn class="mr-5" @click="nueva()" small color="teal" dark rounded><v-icon small>add_circle</v-icon><span class='text-btn'>Nuevo</span></v-btn>
                <v-btn class="mr-5" v-if="btn===true" @click="publica()" small color="teal" dark rounded><v-icon small>public</v-icon><span class='text-btn'>Publicar</span></v-btn>
                <v-btn class="mr-5" v-else small color="teal" dark rounded><v-icon small>public</v-icon><span class='text-btn'>Publicar</span></v-btn>
            </v-card-actions>
        </v-card>
        <v-dialog v-model="dialog" transition="dialog-bottom-transition" max-width="300" persistent>
            <v-card>
            <v-toolbar color="primary" dark>Variante</v-toolbar>
            <v-card-text class="mt-5">
                <v-text-field class='text-caption' v-model="dialogName" label="Nombre" disabled filled outlined dense></v-text-field>
                <v-text-field class='text-caption' v-model="dialogValor" label="Valor" dense clearable></v-text-field>
                <v-btn @click="saveValor()" small color="teal" dark rounded><v-icon small>save</v-icon><span class='text-btn'>Grabar</span></v-btn>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn text @click="CloseDialog()" color="indigo">Close</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <snack :Msg="Msg" :color="color" :msgbox="msgbox"/>
        <dialogo :loading="loading"/>
    </div>
</template>
<style scoped>
.text-size >>> td {
    font-size: 9pt !important;
    height: 25px !important;
    /*background-color: lightyellow;*/ 
}
.col-color >>> th {
    background-color: lightgrey;
}
</style>
<script>
import snack from '@/components/snack.vue';
import dialogo from '@/components/dialogo.vue';
import axios from 'axios';
export default {
    name:'Publicar', 
    components:{dialogo, snack},
    props: ['usu','contrato'],   
    data(){
        return{
            loading:false,
            msgbox:false,
            color:'',
            Msg:'',
            page: 1,
            pageCount: 0,
            itemsPerPage: 4,
            headers: [
                { text: 'Nombre', align: 'start', sortable: true, value: 'name' },
                { text: 'Dato', align: 'start', sortable: false, value: 'valor' },
                { text: 'Accion', align: 'center', sortable: false, value: 'accion' },
            ],
            items:[],
            variantes: [],
            sku:'',
            nombre:'',
            descripcion:'',
            seccion:'',
            categoria:'',
            neto:0,
            impuesto:0,
            iva:0,
            total:0,
            disabledSku:false,
            filledSku:false,
            dialog:false,
            dialogID:'',
            dialogName:'',
            dialogValor:'',
            btn:true,
            itemsPrecio:[], 
            miprecio:'',
            itemsCondicion:[{'tp':'Nuevo','con':'new'},{'tp':'Usado','con':'used'}],
            condicion:'',
            cantidad:'',
            totalStock:0,
        }
    },
    methods:{
        showSnack(){
            setTimeout(()=>{this.msgbox=false},3000)
        },
        onlyNumber ($event) {
            let keyCode = ($event.keyCode ? $event.keyCode : $event.which);
            if ((keyCode < 48 || keyCode > 57)) { // && keyCode !== 46 is dot
                $event.preventDefault();
            }
        },
        clsInput(){
            this.nombre='';
            this.descripcion='';
            this.categoria='';
            this.seccion='';
            this.items=[];
            this.variantes=[];
            this.neto=0;
            this.iva=0;
            this.total=0;
            this.impuesto=0;
            this.dialog=false;
            this.dialogName='';
            this.dialogValor='';
            this.dialogID='';
            this.btn=true;
            this.miprecio='';
            this.itemsPrecio=[];
            this.condicion='';
            this.totalStock=0;
            this.cantidad='';
        },        
        async nombreCategoria(numeroCategoria){
            const response = await axios.get('http://www.app.bodeganet.cl/ApiPHP_ClientesPWA/public/api/inv/categorias/'+ this.contrato);
            const datos=response.data;
            //console.log(datos);
            datos.forEach(element => {
                if(element.ID==numeroCategoria){
                    this.categoria=element.Nombre
                }
            });
        },
        async lookSku(xSku){
            this.loading=true;
            this.msgbox=false;
            this.clsInput();
            if(xSku.trim()!=''){
                this.disabledSku=true;
                this.filledSku=true;
                const response = await axios.get('http://www.app.bodeganet.cl/ApiPHP_ClientesPWA/public/api/inv/producto/'+this.contrato+'/'+xSku);
                const datos=response.data;
                if (datos[0].existe!='si'){
                    this.Msg='Sku no existe !!!'
                    this.msgbox=true;
                    this.color='red';
                    this.showSnack();
                } else {
                    this.sku=datos[0].Sku;
                    this.nombre=datos[0].Nombre;
                    this.itemsPrecio=[
                        {'tipo':'Precio Detalle','monto':datos[0].PrecioLocal},
                        {'tipo':'Precio x Mayor','monto':datos[0].PrecioMayor},
                        {'tipo':'Precio Oferta','monto':datos[0].PrecioOferta}
                    ];
                    // mejoramos la descripcion para publicar
                    var re = /<br>/gi;
                    var str = datos[0].Descripcion;
                    var newstr = str.replace(re, "");
                    this.descripcion=newstr;
                    await this.nombreCategoria(datos[0].Categoria);
                    await this.allStock(xSku);
                    //buscamos la seccion de la categoria directo a ML no necesita Token
                    const response1 = await axios.get('https://api.mercadolibre.com/sites/MLC/domain_discovery/search?q='+this.categoria);
                    const datos1=response1.data;
                    if (datos1.lenght!=0){
                        this.items=[];
                        datos1.forEach(element => {
                            this.items.push({id:element.category_id,name:element.domain_name})
                        });
                    }
                }
            } else {
                this.Msg='ingrese Sku !!!'
                this.msgbox=true;
                this.color='red';
                this.showSnack();
            }
            this.loading=false;
        },
        async allStock(sk){
            const response = await axios.get('http://www.app.bodeganet.cl/ApiPHP_ClientesPWA/public/api/inv/stock/producto/total/'+ this.contrato +'/'+ sk);
            const datos=response.data;
            if (datos[0].existe=='si'){ 
                this.totalStock=datos[0].Total;
            }
        },
        async selectPrecio(nMonto){
            this.neto=0;
            this.iva=0;
            this.total=0;
            this.impuesto=0;
            if (nMonto.trim()!=''){
                this.neto=parseInt(nMonto);
                const response2 = await axios.get('http://www.app.bodeganet.cl/ApiPHP_ClientesPWA/public/api/inv/impuesto/'+this.contrato);
                const datos2=response2.data;
                if (datos2[0].existe!='si'){ 
                    this.impuesto=19;
                    this.iva=Math.round(((parseInt(this.neto)*this.impuesto)/100)+0.1);
                } else {
                    this.impuesto=datos2[0].Iva;
                    this.iva=Math.round(((parseInt(this.neto)*this.impuesto)/100)+0.1);
                }
                this.total=parseInt(this.neto)+parseInt(this.iva);
            }
        },
        async attrSku(idSec){
            //directo a ML no necesita Token
            const response = await axios.get('https://api.mercadolibre.com/categories/'+idSec+'/attributes');
            const datos=response.data;
            if (datos.lenght!=0){
                this.variantes=[];
                datos.forEach(element => {
                    for (const property in element.tags) {
                        if(property=='required'){
                            this.variantes.push({id:element.id,name:element.name,valor:'sin valor'})
                        }
                    }
                });
            }
        },
        editar(item){
            this.dialog=true;
            this.dialogID=item.id;
            this.dialogName=item.name;
            this.dialogValor=item.valor;
        },
        CloseDialog(){
            this.dialog=false;
        },
        saveValor(){
            let indice = this.variantes.findIndex(busca => busca.id === this.dialogID);
            if (this.dialogValor.trim()!=''){
                this.variantes[indice].valor = this.dialogValor;
            } else { this.variantes[indice].valor = 'sin valor' }
            this.dialog=false;
        },
        nueva(){
            this.sku='';
            this.disabledSku=false;
            this.filledSku=false;
            this.clsInput();
        },
        async publica(){
            // validamos las entradas
            if (this.sku.trim()!='' && 
                this.seccion.trim()!='' && 
                this.condicion.trim()!='' &&
                this.cantidad.trim()!=''){
                if (parseInt(this.cantidad)!=0){
                    if (parseInt(this.total)!=0){
                        //publicar
                        await this.publicarProducto();
                    } else {
                        this.Msg='Ingrese Precio !!!'
                        this.msgbox=true;
                        this.color='red';
                        this.showSnack();
                    }
                } else {
                    this.Msg='Ingrese Cantidad !!!'
                    this.msgbox=true;
                    this.color='red';
                    this.showSnack();
                }
            } else {
                this.Msg='Ingrese datos Obligatorios !!!'
                this.msgbox=true;
                this.color='red';
                this.showSnack();
            }
        },
        async publicarProducto(){
            this.loading=true;
            let JsonProducto;
            let JsonImagen=[];
            let JsonCombina=[];
            let JsonPicture=[];
            if (this.variantes.length!=0){
                //imagenes
                JsonImagen=[];
                const response0 = await axios.get('http://www.app.bodeganet.cl/ApiPHP_ClientesPWA/public/api/inv/producto/imagen/'+this.contrato+'/'+this.sku);
                const datos0=response0.data;    
                if (datos0[0].existe=='si'){ 
                    for (let index = 0; index < datos0.length; index++) {
                        const element = datos0[index];
                        JsonImagen.push(element.Imagen);                        
                    }
                }
                //combinaciones
                JsonCombina=[];
                this.variantes.forEach(element => {
                    JsonCombina.push({"id":element.id,"value_name":element.valor})
                });
                //completamos los parametros
                JsonProducto=
                {
                    "title":this.nombre,
                    "category_id":this.seccion,
                    "currency_id":"CLP",
                    "condition":this.condicion,
                    "listing_type_id":"gold_special",
                    "attributes":[
                        {
                            "id":"SELLER_SKU",
                            "value_name":this.sku
                        }
                    ],
                    "variations": [
                        {
                            "price":this.total,
                            "available_quantity":this.cantidad,
                            "picture_ids": JsonImagen,
                            "attribute_combinations":JsonCombina
                        }		
                    ]
                }
            } else {
                //busca las imagenes del producto
                JsonPicture=[];
                const response1 = await axios.get('http://www.app.bodeganet.cl/ApiPHP_ClientesPWA/public/api/inv/producto/imagen/'+this.contrato+'/'+this.sku);
                const datos1=response1.data;    
                if (datos1[0].existe=='si'){ 
                    for (let index = 0; index < datos1.length; index++) {
                        const element = datos1[index];
                        JsonPicture.push({"source":element.Imagen})                       
                    }
                }
                //completamos los parametros
                JsonProducto=
                {
                    "title":this.nombre,
                    "category_id":this.seccion,
                    "currency_id":"CLP",
                    "condition":this.condicion,
                    "listing_type_id":"gold_special",
                    "price":this.total,
                    "available_quantity":this.cantidad,
                    "pictures": JsonPicture,
                    "attributes":[
                        {
                            "id":"SELLER_SKU",
                            "value_name":this.sku
                        }
                    ]
                }
            }
            //obtener Token
            let token='';
            const response = await axios.get('http://www.app.bodeganet.cl/ApiEcomerce/public/api/ML/token/'+this.contrato);
            const datos=response.data;
            if (datos.message=='Token Actualizado' || datos.message=='Token Activo'){
                token=datos.valor;
                //publicar
                try {
                    const rutaProducto='https://api.mercadolibre.com/items';
                    const resProducto= await axios.post(rutaProducto,JsonProducto,{headers : {'content-type': 'application/json','Authorization':'Bearer '+ token}});
                    const productos=resProducto.data;
                    if (productos.length!=0){
                        // crear la descripcion del producto
                        const JsonDescripcion={
                            "plain_text":this.descripcion
                        }
                        const rutaDescripcion='https://api.mercadolibre.com/items/'+productos.id+'/description';
                        const resDescripcion= await axios.post(rutaDescripcion,JsonDescripcion,{headers : {'content-type': 'application/json','Authorization':'Bearer '+ token}});
                        const Descripciones=resDescripcion.data;                    
                        if (Descripciones.length!=0){
                            this.Msg='Producto Creado en ML !!!'
                            this.msgbox=true;
                            this.color='success';
                            this.showSnack();
                            this.btn=false;
                        } else {
                            this.Msg='Producto Creado Sin Descripcion !!!'
                            this.msgbox=true;
                            this.color='orange';
                            this.showSnack();
                            this.btn=false;
                        }
                    } else { 
                        this.Msg='Producto NO Creado !!!'
                        this.msgbox=true;
                        this.color='red';
                        this.showSnack();
                        this.btn=false;
                    }                    
                } catch (e) {
                    this.Msg='Revisar Datos y Descripcion !!!'
                    this.msgbox=true;
                    this.color='red';
                    this.showSnack();
                    console.log(e);
                }
            } else { 
                this.Msg='Contrato Sin Token !!!'
                this.msgbox=true;
                this.color='red';
                this.showSnack();
                this.btn=false;
            }
            this.loading=false;
        },
    }
}
</script>