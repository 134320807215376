<template>
    <div>
          <v-list dense class="mt-n10">
            <v-list-item class="mt-5">
              <v-list-item-action>
                <v-list-item-avatar>
                  <v-img src="@/assets/user.png"></v-img>
                </v-list-item-avatar>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title class="title">{{usu.toUpperCase()}}</v-list-item-title>
                <v-list-item-subtitle>{{contrato}}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-list>
          <!-- <v-container>
          <v-icon>person</v-icon>
          <span class="text-h6">{{usu.toUpperCase()}}</span><br/>
          <strong class="mt-5 text-body-2">{{contrato}}</strong>
          </v-container> -->
          <v-divider class="mt-n5"></v-divider>
          <v-list nav dense shaped>
            <v-list-item-group color="success" v-model="selectedItem">
              <v-list-item v-for="(item, i) in items" :key="i" @click="OpcMenu(item)">
                <v-list-item-icon>
                  <v-icon v-text="item.icon"></v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title v-text="item.text"></v-list-item-title>                  
                </v-list-item-content>
              </v-list-item>
            </v-list-item-group>
          </v-list>
    </div>
</template>
<script>
import EventBus from '../bus'
export default {
    name: 'Menu',
    props: ['usu','contrato'],
 data(){
    return {
     selectedItem: null,
      items: [
        { componente: 'DashBoard' , text: 'DashBoard', icon: 'dashboard' },
        { componente: 'Productos' , text: 'Productos', icon: 'unarchive' },
        { componente: 'Pedidos' , text: 'Compra / Cotización', icon: 'archive' },
        { componente: 'Dte' , text: 'Venta / Devolución', icon: 'assignment' },
        { componente: 'Caja' , text: 'Flujo Caja', icon: 'credit_card' },
        { componente: 'Inventario' , text: 'Inventario', icon: 'iso' },
        { componente: 'Cartera' , text: 'Balance Cartera', icon: 'account_balance_wallet' },
        { componente: 'Credito' , text: 'Credito Cobranza', icon: 'equalizer' },
        { componente: 'Setup' , text: 'Configuración', icon: 'settings' },
        { componente: 'Reportes' , text: 'Reportes', icon: 'report' },
        { componente: 'Ecomerce' , text: 'Ecomerce', icon: 'shopping_basket' }
      ],
    }
  },
  methods:{
      OpcMenu(Opc){
        EventBus.$emit('Opcion-Menu', Opc)
      }
  }
}
</script>