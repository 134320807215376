<template>
    <div>
        <v-card >
            <v-card-title class="blue-grey white--text">COTIZACIÓN</v-card-title>
            <v-card-text>
                <v-form>
                    <v-container>
                        <v-row>
                            <v-col cols="3" md="1">
                                <div class="text-center">
                                    <v-btn @click="editar()" color="cyan" fab x-small dark><v-icon>edit</v-icon></v-btn>                                    
                                </div>
                            </v-col>
                            <v-col cols="9" md="3">
                                <v-text-field class='text-caption' v-model="orden" label="Numero Orden" :disabled="soloOrden" :filled="soloOrden" outlined dense></v-text-field>
                            </v-col>
                            <v-col cols="9" md="4">
                                <!-- <v-select v-if="pcotiza===true" class='text-caption' v-model="Cliente" :items="itemCliente" label="Cliente" :disabled="disabledOrden" :filled="filledOrden" dense outlined></v-select> -->
                                <v-combobox v-if="pcotiza===true" @change="numeroCliente(Cliente)" :hint="rutCliente" persistent-hint class='text-caption' v-model="Cliente" :items="itemCliente" label="Cliente" dense outlined :disabled="disabledOrden" :filled="filledOrden"></v-combobox>
                                <v-text-field v-else class='text-caption' :hint="rutCliente" persistent-hint v-model="Cliente" label="Cliente" disabled filled outlined dense></v-text-field>
                            </v-col>
                            <v-col cols="3" md="1">
                                <v-btn v-if="pcotiza===true" @click="solicitaOrden(Cliente)" class="mt-1" small color="teal" dark rounded><v-icon small>add</v-icon></v-btn>
                                <v-btn v-else @click="buscaOrden(orden)" class="mt-1" small color="teal" dark rounded><v-icon small>search</v-icon></v-btn>
                            </v-col>
                            <v-col cols="12" md="3">
                                <v-alert class='text-caption' v-if="existe=='si'" dense text type="success">
                                    Orden Existe !!!
                                </v-alert>
                                <v-alert class='text-caption' v-else-if="existe=='no'" dense text type="warning">
                                    Nueva Orden !!!
                                </v-alert>
                                <v-alert class='text-caption' v-else dense text type="info">
                                    Selecciona Datos !!!
                                </v-alert>
                            </v-col >
                        </v-row>
                        <v-divider></v-divider>
                        <v-row class="mt-2">
                            <v-col cols="2" md="1" style="margin-top:-10px">
                                <v-checkbox class="float-end" @click="CheckBarra()" v-model="checkbox" :disabled="disabledDatos"></v-checkbox>
                            </v-col>
                            <v-col cols="10" md="2">
                                <!-- <v-combobox id="barr" v-on:change="search_BarraItem(listabarra)" class='text-caption' :items="itemProductos" v-model="listabarra" item-text="bar"  label="Codigo Barra" dense outlined :disabled="disabledDatos" :filled="filledDatos"></v-combobox> -->
                                <v-text-field id="barr" v-on:change="search_BarraItem(listabarra)" class='text-caption' @keypress="onlyNumber" v-model="listabarra" label="Cod. Barra" outlined dense :disabled="disabledCheck" :filled="filledCheck"></v-text-field>
                            </v-col>
                            <v-col cols="10" md="4">
                                <v-combobox :hint="SkuItem" persistent-hint v-on:change="search_SkuItem(listaproducto)" class='text-caption' v-model="listaproducto" :items="itemProductos" item-text="name"  label="Selecciona Producto" dense outlined :disabled="disabledBarra" :filled="filledBarra"></v-combobox>
                            </v-col>
                            <v-col cols="2" md="1">
                                <v-btn v-if="disabledBarra===true" class="mt-1" small color="grey" dark rounded><v-icon small>find_replace</v-icon></v-btn>
                                <v-btn v-else @click="inventa=true" class="mt-1" small color="indigo" dark rounded><v-icon small>find_replace</v-icon></v-btn>
                            </v-col>
                            <v-col cols="12" md="2">
                                <v-select v-on:change="buscaPrecio(listacodigo)" class='text-caption' v-model="miprecio" :items="itemsPrecio" label="Tipo Precio" dense outlined :disabled="disabledDatos" :filled="filledDatos"></v-select>
                             </v-col>
                            <v-col cols="12" md="2">
                                <v-text-field class='text-caption' @keypress="onlyNumber" v-model="precio" label="Precio" outlined dense required disabled filled></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="6" md="3">
                                <v-select class='text-caption' v-on:change="stockSku(bodega)" v-model="bodega" :items="itemBodegas" label="Bodega" dense outlined :hint="tStock" persistent-hint :disabled="disabledDatos" :filled="filledDatos"></v-select>                                
                            </v-col>
                            <v-col cols="6" md="2">
                                <v-text-field class='text-caption' @keypress="onlyNumber" v-model="cantidad" label="Cantidad" outlined dense required clearable :disabled="disabledDatos" :filled="filledDatos"></v-text-field>
                            </v-col>
                            <v-col cols="12" md="3">
                                <v-text-field class='text-caption' v-model="observa" label="Observacion" outlined dense required clearable :disabled="disabledDatos" :filled="filledDatos"></v-text-field>
                            </v-col>
                            <v-col cols="12" md="2">
                                <v-alert class='text-caption' dense text border="left" color="grey">Neto $ {{total.toLocaleString()}}</v-alert> 
                            </v-col>
                            <v-col cols="12" md="2">
                                <v-alert class='text-caption' dense text border="left" color="warning">Total $ {{venta.toLocaleString()}}</v-alert> 
                            </v-col>
                        </v-row>
                    </v-container>
                </v-form>
            </v-card-text>
            <v-card-actions style="margin-top:-25px">
                <v-btn @click="dialogVentas()" color="indigo" fab dark>{{nVentas}}</v-btn>
                <h3 class="ml-2 text-caption">Total Items</h3>

                <v-btn class="ml-5 mr-5" color="teal" icon
                        v-clipboard:copy="copiar" v-clipboard:success="onCopy" v-clipboard:error="onError">
                        <v-icon>content_copy</v-icon>
                </v-btn>

                <v-spacer></v-spacer>
                <v-btn @click="newVenta()" class="mr-5" small color="teal" dark rounded><v-icon small>add_circle</v-icon><span class='text-btn'>Nuevo</span></v-btn>
                <v-btn @click="saveVenta()" class="mr-5" small color="teal" dark rounded><v-icon small>save</v-icon><span class='text-btn'>Grabar</span></v-btn>
            </v-card-actions>
        </v-card>
        <v-dialog v-model="dialog" transition="dialog-bottom-transition" max-width="80%" persistent>
        <v-card>
            <v-card-title>
                <v-btn @click="dialog=false" color="red" small dark><v-icon small>close</v-icon>Close</v-btn>
                <v-spacer></v-spacer>
                <a target="_blank" :href="html" style="text-decoration: none"><v-icon>print</v-icon></a>
            </v-card-title>
            <v-card-text>
                <v-data-table dark :search="likesearch" :headers="headers" :items="items" :page.sync="page" :items-per-page="itemsPerPage" hide-default-footer class="elevation-1 text-size" @page-count="pageCount = $event">
                    <template v-slot:item.accion="{ item }">
                        <v-icon small @click="deleteVenta(item.id)">delete</v-icon>
                    </template>
                </v-data-table>
            </v-card-text>
            <v-card-actions>
                <v-row>
                    <v-col cols="12" md="6">
                        <v-pagination v-model="page" :length="pageCount"></v-pagination>
                    </v-col>
                    <v-col cols="12" md="4">
                        <v-text-field class='ml-5 mr-2 text-caption' v-model="likesearch" label="Buscar Producto" dense required></v-text-field>
                    </v-col>
                    <v-col cols="12" md="2" class='mt-3'>
                        <a class="mt-2 ml-2" v-if="descargaCSV===false" href="javascript:void(0)" @click="fileCSV()"><img src="@/assets/csv.png" width="16px" height="20px"/></a>
                        <a class="ml-2" v-else :href="rutaCSV" style="text-decoration: none"><v-icon color='teal'>cloud_download</v-icon><span class="ml-2 text-caption">Descarga</span></a>
                    </v-col>
                </v-row>
            </v-card-actions>
        </v-card>
        </v-dialog>
        <!-- dialog inventa --> 
        <v-dialog v-model="inventa" transition="dialog-bottom-transition" max-width="600" persistent>
        <v-card>
            <v-card-title>
                <v-btn @click="inventa=false" color="red" small dark><v-icon small>close</v-icon>Close</v-btn>
                <v-spacer>
                    <div class="float-right">
                    <v-text-field class='ml-5 mr-2 text-caption' v-model="inventasearch" label="Buscar Producto" dense required></v-text-field>
                    </div>
                </v-spacer>
            </v-card-title>
            <v-card-text>
                <v-data-table @click:row="handleClick" dense dark :search="inventasearch" :headers="inventatitulo" :items="inventalista" :page.sync="inventapage" :items-per-page="inventaitemsPerPage"  @page-count="inventapageCount = $event" hide-default-footer class="elevation-1 text-size">
                </v-data-table>
                <v-pagination v-model="inventapage" :length="inventapageCount"  :total-visible="5"></v-pagination>
            </v-card-text>
        </v-card>
        </v-dialog>


        <snack :Msg="Msg" :color="color" :msgbox="msgbox"/>
        <dialogo :loading="loading"  />        
    </div>
</template>
<script>
import snack from '@/components/snack.vue';
import dialogo from '@/components/dialogo.vue';
import axios from 'axios';
export default {
    name:'Venta', 
    components:{dialogo, snack},
    props: ['usu','contrato'],   
    data(){
        return{
            pcotiza:true,
            hoy:'',
            //contrato:'123',
            observa:'',
            tStock:'',
            loading:false,
            msgbox:false,
            color:'',
            Msg:'',
            existe:'',
            disabledOrden:false,
            filledOrden:false,
            disabledDatos:true,
            filledDatos:true,
            disabledCheck:true,
            filledCheck:true,
            disabledBarra:true,
            filledBarra:true,
            soloOrden:true,
            btnGrabar:true,
            orden:'',
            itemCliente:[],
            arrayCliente:[],
            Cliente:'',
            listaproducto:'',
            listabarra:'',
            listanombre:'',
            listacodigo:'',
            itemProductos:[],
            arrayProductos:[],
            bodega:'',
            itemBodegas:[],
            arrayBodegas:[],
            precio:'',
            cantidad:'',
            radios:'Neto',
            total:0,
            nCliente:0,
            nProducto:0,
            nBodega:0,
            cv:'V',
            nVentas:0,
            // dialog Ventas
            dialog:false,
            likesearch:'',
            page: 1,
            pageCount: 0,
            itemsPerPage: 8,
            headers: [
                { text: 'Sku', align: 'start', sortable: true, value: 'sku' },
                { text: 'Producto', align: 'start', sortable: true, value: 'producto' },
                { text: 'Precio', align: 'center', sortable: true, value: 'precio' },
                { text: 'Cantidad', align: 'center', sortable: true, value: 'cantidad' },
                { text: 'Bodega', align: 'center', sortable: true, value: 'bodega' },
                { text: 'Accion', align: 'center', sortable: true, value: 'accion' },
            ],
            items: [],
            itemsPrecio:['Precio Detalle','Precio x Mayor','Precio Oferta'], 
            miprecio:'',
            html:'',
            descargaCSV:false,
            rutaCSV:'',
            SkuItem:'',
            nStock:0,
            copiar:'',
            checkbox:false,
            rutCliente:'',
            impuesto:'',
            venta:0,
            inventa:false,
            inventasearch:'',
            inventatitulo:[
                { text: 'Sku', align: 'center', sortable: true, value: 'iSku', width:'' },
                { text: 'Producto', align: 'start', sortable: true, value: 'iProducto' },
            ],
            inventalista:[],
            inventapage: 1,
            inventapageCount: 0,
            inventaitemsPerPage: 10,
        }
    },
    async mounted(){
        await this.buscaCliente();
        await this.buscarProducto();
        await this.buscaImpuesto();
        //await this.buscarBodegas()
        //console.log(this.usu);
    },
    methods:{
        handleClick(txtOpcion) {
            this.listanombre=txtOpcion.iProducto;
            this.listaproducto=txtOpcion.iProducto;
            this.listacodigo=txtOpcion.iSku;
            this.SkuItem="Sku: "+this.listacodigo;
            this.nProducto=this.listacodigo;
            this.skuBodegas();
            this.inventa=false;
        },
        async buscaImpuesto(){
            const response = await axios.get('http://www.app.bodeganet.cl/ApiPHP_ClientesPWA/public/api/inv/impuesto/'+this.contrato);
            const datos=response.data;
            this.impuesto=0;
            if (datos[0].existe=='si'){
                this.impuesto=datos[0].Iva
            }
        },
        async fileCSV(){
            this.loading=true;
            const response = await axios.get('http://www.app.bodeganet.cl/ApiPHP_ClientesPWA/public/api/inv/fileCsv/cotiza/'+this.contrato+'/'+this.orden+'/'+this.nCliente);
            const datos=response.data;
            //console.log(datos);
            if (datos=='si'){
                this.descargaCSV=true;
                this.rutaCSV='http://www.app.bodeganet.cl/ApiPHP_ClientesPWA/public/buzon/'+this.contrato+'/'+this.orden+'.csv';
            }
            this.loading=false;
        },
        editar(){
            this.newVenta();
            this.pcotiza=false;
            this.soloOrden=false;
        },

            onCopy: function (e) {
                this.Msg='Orden '+ e.text +' Copiada';
                this.msgbox=true;
                this.color='orange';
                this.showSnack();
            },
            onError: function (e) {
                this.Msg='Orden NO Copiada';
                this.msgbox=true;
                this.color='red';
                this.showSnack();
            },

        async buscaOrden(nOrden){
            this.loading=true;
            const response = await axios.get('http://www.app.bodeganet.cl/ApiPHP_ClientesPWA/public/api/inv/orden/'+this.contrato+'/'+nOrden);
            const datos=response.data;
            if (datos[0].existe=='si'){
                this.existe='si' 
                this.soloOrden=true;
                this.disabledDatos=false;
                this.filledDatos=false;
                this.disabledBarra=false;
                this.filledBarra=false;
                this.Cliente=datos[0].Nombre;
                this.numeroCliente(this.Cliente);
                let newTotal=0;
                this.nVentas=0;
                datos.forEach(element => {
                    newTotal=newTotal+parseInt(element.Total); 
                    this.nVentas=parseInt(this.nVentas)+parseInt(element.Reg);                        
                });
                this.total=newTotal;
                this.venta=newTotal+Math.round((newTotal*parseInt(this.impuesto)/100));
                this.copiar=''+nOrden;
            } else {
                this.existe='';
                this.Msg='Orden no Existe o con DTE';
                this.msgbox=true;
                this.color='red';
                this.showSnack();
            }
            this.loading=false;
        },
        onlyNumber ($event) {
         //console.log($event.keyCode); //keyCodes value
            let keyCode = ($event.keyCode ? $event.keyCode : $event.which);
            if ((keyCode < 48 || keyCode > 57)) { // && keyCode !== 46 is dot
                $event.preventDefault();
            }
        },
        showSnack(){
            setTimeout(()=>{this.msgbox=false},3000)
        },
        dialogVentas(){
            if (this.nCompras!=0){
                this.dialog=true;
                this.descargaCSV=false;
                this.rutaCSV='';
                this.datosDialog()
            }
        },
        async datosDialog(){
            const response = await axios.get('http://www.app.bodeganet.cl/ApiPHP_ClientesPWA/public/api/inv/orden/entidad/'+this.cv+'/'+this.contrato+'/'+this.orden+'/'+this.nCliente);
            const datos=response.data;
            let newTotal;
            newTotal=0;
            this.items=[];
            if (datos[0].existe=='si'){   
                this.html='http://www.app.bodeganet.cl/ApiPHP_ClientesPWA/public/api/inv/prt/cotiza/'+this.contrato+'/'+this.orden+'/'+this.nCliente;            
                datos.forEach(element => {
                    this.items.push({
                        'id':element.ID,
                        'sku':element.Sku,
                        'producto':element.Producto,
                        'precio':new Intl.NumberFormat("de-DE").format(parseFloat(element.Precio)),
                        'cantidad':new Intl.NumberFormat("de-DE").format(parseFloat(element.Cantidad)),
                        'bodega':element.Bodega,
                    })
                    newTotal=newTotal+(parseInt(element.Precio)*parseInt(element.Cantidad));      
                });
            }
            this.total=newTotal;
            this.venta=newTotal+Math.round((newTotal*parseInt(this.impuesto)/100));
        },
        async deleteVenta(n){
            this.loading=true;
            const response = await axios.delete('http://www.app.bodeganet.cl/ApiPHP_ClientesPWA/public/api/inv/orden/entidad/delete/'+this.contrato+'/'+n);
            const datos=response.data;
            if (response.data=='ok'){
                await this.datosDialog()
                this.nVentas=this.nVentas-1
            }           
            this.loading=false;
        },
        clsInput(){
            this.tStock='';
            this.SkuItem='';
            this.disabledOrden=false;
            this.filledOrden=false;
            this.disabledDatos=true;
            this.filledDatos=true;
            this.disabledCheck=true;
            this.filledCheck=true;
            this.disabledBarra=true;
            this.filledBarra=true;
            this.soloOrden=true; 
            this.listaproducto='';    
            this.listabarra='';       
            this.listanombre='';
            this.listacodigo='';
            this.precio='';
            this.cantidad='';
            this.observa='';
            this.nCliente=0;
            this.nProducto=0;
            this.bodega='';
            this.nBodega=0;
            this.existe='';
            this.nVentas=0;
            this.dialog=false;
            this.items=[];
            this.pcotiza=true;
            this.hoy='';
            this.html='';
            this.miprecio='';
            this.precio='';
            this.descargaCSV=false;
            this.rutaCSV='';
            this.nStock=0;
            this.copiar='';
            this.checkbox=false;
            this.rutCliente='';
            this.inventasearch='';
        },
        newVenta(){
            this.orden='';
            this.Cliente='';
            this.total=0;
            this.venta=0;
            this.clsInput()
        },
        async buscaCliente(){
            const response = await axios.get('http://www.app.bodeganet.cl/ApiPHP_ClientesPWA/public/api/inv/lista/clientes/'+this.contrato);
            const datos=response.data;
            //console.log(datos);
            this.itemCliente=[];
            this.arrayCliente=[];
            if (datos[0].existe=='si'){
                datos.forEach(element => {
                    this.itemCliente.push(element.Nombre);
                    this.arrayCliente.push({id:element.ID,nombrecli:element.Nombre,rutcli:element.Rut})        
                });
            }
        },
        async buscarProducto(){
            const response = await axios.get('http://www.app.bodeganet.cl/ApiPHP_ClientesPWA/public/api/inv/productos/'+this.contrato);
            const datos=response.data;
            //console.log(datos);
            this.itemProductos=[];
            this.arrayProductos=[];
            this.inventalista=[];
            if (datos[0].existe=='si'){
                datos.forEach(element => {
                    this.itemProductos.push({"name":element.Nombre,"code":element.Sku,"bar":element.Barra});
                    this.arrayProductos.push({
                        sku:element.Sku,
                        nameSku:element.Nombre,
                        precioSku:element.PrecioLocal,
                        precioMayor:element.PrecioMayor,
                        precioOferta:element.PrecioOferta
                    });
                    this.inventalista.push({'iSku':element.Sku,'iProducto':element.Nombre});
                });
            }
        },
        async buscarBodegas(){
            const response = await axios.get('http://www.app.bodeganet.cl/ApiPHP_ClientesPWA/public/api/inv/bodegas/'+this.contrato);
            const datos=response.data;
            //console.log(datos);
            this.itemBodegas=[];
            this.arrayBodegas=[];
            if (datos[0].existe=='si'){
                datos.forEach(element => {
                    this.itemBodegas.push(element.Nombre);
                    this.arrayBodegas.push({numBodega:element.ID,nameBodega:element.Nombre})        
                });
            }
        },
        CheckBarra(){
            this.listaproducto='';
            this.listanombre='';
            this.listacodigo='';
            this.SkuItem='';
            this.nProducto=0;
            if (this.checkbox===false){
                this.disabledCheck=true;
                this.filledCheck=true;
                this.disabledBarra=false;
                this.filledBarra=false;
                this.listabarra='';
            } else {
                this.disabledCheck=false;
                this.filledCheck=false;
                this.disabledBarra=true;
                this.filledBarra=true;
            }
        },
        search_SkuItem(itemPro){ // variable Global
            this.listanombre=itemPro.name;
            this.listacodigo=itemPro.code;
            this.SkuItem="Sku: "+this.listacodigo;
            this.nProducto=this.listacodigo;
            this.skuBodegas();
        },
        search_BarraItem(codBarra){ // variable Global
            //console.log(codBarra);
            this.listaproducto='';
            this.listanombre='';
            this.listacodigo='';
            this.SkuItem='';
            this.nProducto=0;
            var elemento = document.getElementById("barr");
            elemento.blur();
            const resArray = this.itemProductos.find( datoArray => datoArray.bar === codBarra );
            if (resArray){
                this.listaproducto=resArray.name;
                this.listanombre=resArray.name;
                this.listacodigo=resArray.code;
                this.SkuItem="Sku: "+this.listacodigo;
                this.nProducto=this.listacodigo;
                this.skuBodegas();
            }
        },
        valorSku(SkuProducto){
            this.arrayProductos.forEach(element => {
                if (SkuProducto==element.sku){
                    switch (this.miprecio) {
                        case 'Precio Detalle':
                            this.precio=element.precioSku
                            break;
                        case 'Precio x Mayor':
                            this.precio=element.precioMayor
                            break;
                        case 'Precio Oferta':
                            this.precio=element.precioOferta
                            break;
                        default:
                            this.precio=''
                            break;
                    }
                }
            });
        },
        numeroCliente(txtCliente){
            this.arrayCliente.forEach(element => {
                if (txtCliente==element.nombrecli){
                    this.nCliente=element.id;
                    this.rutCliente="Rut: "+element.rutcli;
                }
            });
        },
        numeroBodega(txtBodega){
            this.arrayBodegas.forEach(element => {
                if (txtBodega==element.nameBodega){
                    this.nBodega=element.numBodega
                }
            });
        },
        async stockSku(a){
            this.cantidad='';
            await this.numeroBodega(a);
            if (this.nBodega!=0){
                await this.totalStock();
            } else {
                this.tStock='Error de Bodega';
            }
        },
        async totalStock(){
            const response = await axios.get('http://www.app.bodeganet.cl/ApiPHP_ClientesPWA/public/api/inv/stock/bodegas/producto/total/'+ this.contrato +'/'+ this.nBodega + '/' + this.nProducto);
            const datos=response.data;
            if (datos[0].existe=='si'){ 
                this.tStock='Total Stock: '+datos[0].Total;
                this.nStock=datos[0].Total;
            }
            if (datos[0].existe=='no'){ 
                this.tStock='Sin Stock';
                this.nStock=0;
            } 
            if (datos[0].existe=='error'){ 
                this.tStock='Error de Stock';
                this.nStock=0;
            } 
        },
        async solicitaOrden(tCliente){
            this.clsInput();
            this.loading=true;
            const timestamp = Date.now();
            this.copiar=''+timestamp;
            //console.log(timestamp);
            this.orden=timestamp;
            const nOrden=timestamp;
            this.total=0;
            this.venta=0;
            if (Number.isInteger(nOrden/1)){
                if (tCliente.trim()!=''){
                    this.numeroCliente(tCliente);
                    this.disabledOrden=true;
                    this.filledOrden=true;
                    this.disabledDatos=false;
                    this.filledDatos=false;
                    this.disabledBarra=false;
                    this.filledBarra=false;
                    this.existe='no'                        
                } else {
                    this.Msg='Seleccione Cliente'
                    this.msgbox=true;
                    this.color='info';
                    this.showSnack();
                    this.newVenta()
                    this.existe=''                        
                }
            } else {
                this.Msg='Orden no Numerica'
                this.msgbox=true;
                this.color='info';
                this.showSnack();
                this.newVenta()
                this.existe=''                        
            }
            this.loading=false;
        },
        async buscaPrecio(SkuProducto){
            //console.log(SkuProducto);
            this.precio='';
            this.valorSku(SkuProducto);
        },
        async skuBodegas(){
            this.bodega='';
            this.itemBodegas=[];
            this.arrayBodegas=[];
            this.nBodega=0;
            this.tStock='';
            this.cantidad='';
            this.observa='';
            this.miprecio='';
            this.precio='';
            const response = await axios.get('http://www.app.bodeganet.cl/ApiPHP_ClientesPWA/public/api/inv/stock/bodegas/producto/'+ this.contrato +'/'+ this.nProducto);
            const datos=response.data;
            //console.log(datos);
            datos.forEach(element => {
                this.itemBodegas.push(element.Nombre);
                this.arrayBodegas.push({numBodega:element.ID,nameBodega:element.Nombre})        
            });
        },
        saveVenta(){
            this.loading=true;
            if (this.contrato!='' && this.orden!='' && this.Cliente!='' && 
                this.bodega!='' && this.cantidad!='' && this.precio!=''){
                if (this.nProducto!=0 && this.nCliente!=0 && this.nBodega!=0 && this.listanombre!=''){
                    //this.html='http://www.app.bodeganet.cl/ApiPHP_ClientesPWA/public/api/inv/cotiza/orden/'+this.contrato+'/'+this.orden;
                    if(this.nStock>=1 && this.nStock>=parseInt(this.cantidad)){
                        this.save();
                    } else {
                        this.Msg='Producto Sin Stock'
                        this.msgbox=true;
                        this.color='red';
                        this.showSnack();
                    }
                } else {
                    this.Msg='Intentelo Nuevamente'
                    this.msgbox=true;
                    this.color='red';
                    this.showSnack();
                }
            } else {
                this.Msg='Datos sin Completar'
                this.msgbox=true;
                this.color='info';
                this.showSnack();
            }
            this.loading=false;
        },
        async save(){
            var parametros={
                'contrato': this.contrato,
                'orden': this.orden,
                'entidad': this.nCliente,
                'tipo': this.radios,
                'cv': this.cv,
                'sku':this.nProducto,
                'producto':this.listanombre,
                'precio':this.precio,
                'cantidad':this.cantidad,
                'bodega': this.nBodega,
                'observa':this.observa,
                'usuario':this.usu,
                'referencia':'0'};
            const response = await axios.post('http://www.app.bodeganet.cl/ApiPHP_ClientesPWA/public/api/inv/compraventa/nueva',parametros);
            const datos=response.data;
            //console.log(datos);
            if (datos=='si'){ 
                this.existe='si';    
                this.Msg='Producto Grabado !!!'
                this.msgbox=true;
                this.color='success';
                this.showSnack();
                this.disabledDatos=false;
                this.filledDatos=false;
                this.btnGrabar=false;
                this.nVentas++;
                this.total=this.total+(parseInt(this.precio)*parseInt(this.cantidad));
                this.venta=parseInt(this.total)+Math.round((parseInt(this.total)*parseInt(this.impuesto)/100));
                this.listaproducto='';
                this.listanombre='';
                this.listacodigo='';
                this.miprecio='';
                this.precio='';
                this.bodega='';
                this.tStock='';
                this.SkuItem='';
                this.cantidad='';
                this.observa='';
                this.observa='';
                this.listabarra='';
                //enviamos el foco si check es true
                if(this.checkbox===true){
                    var elemento = document.getElementById("barr");
                    elemento.focus();
                }
            } 
            if (datos=='error'){
                this.Msg='Conexion Inestable, intentelo mas tarde'
                this.msgbox=true;
                this.color='red';
                this.showSnack();
                this.newVenta();
                this.existe=''  
            }         
        }
    }
}
</script>