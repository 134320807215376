<template>
    <div style="margin-top:-15px">
        <h3>Productos en Stock</h3>
        <v-divider></v-divider>
        <div class="mt-2">
          <!-- full screen -->
          <div class="hidden-sm-and-down">
            <div id="ventana">
              <div id="datos">
                <v-data-table :headers="headerProductos" :items="itemProductos" class="elevation-1 text-size" hide-default-footer dark>
                    <template v-slot:item.tStore="{ item }">
                        <v-chip small :color="ColorStore(item.numStore)" dark>
                            {{ item.tStore }}%
                        </v-chip>
                    </template>
                </v-data-table>
              </div>
            </div>
          </div>
          <!-- mobile screen -->
          <div class="hidden-sm-and-up">
            <v-data-table :headers="headerProductos" :items="itemProductos" class="elevation-1 text-size" hide-default-footer dark>
                <template v-slot:item.tStore="{ item }">
                    <v-chip small :color="ColorStore(item.numStore)" dark>
                        {{ item.tStore }}%
                    </v-chip>
                </template>
            </v-data-table>
          </div>
        </div>
    </div>
</template>
<style scoped>
.text-size >>> td {
    font-size: 9pt !important;
    height: 33px !important;
    /* background-color: lightyellow; */
}
#ventana {
	height: 180px;
	width: 100%;
	/* border: 1px solid #ddd;
	background: #f1f1f1; */
    background: #212121;
	overflow-y: scroll;
}
#datos {
	height: auto;
}
</style>
<script>
import axios from 'axios';
export default {
  name: 'DashProductos',
  props: ['usu','contrato'],
  data(){
    return {
      //contrato:'123',
      headerProductos:[
        {text: 'Bodega',align: 'left',sortable: true, value: 'tBodega'},
        {text: 'Categorias (u)',align: 'center',sortable: false, value: 'tCategorias'},
        {text: 'Productos (u)',align: 'center',sortable: false, value: 'tProductos'},
        {text: 'Compra/Venta',align: 'center',sortable: true, value: 'tMvto'},
        {text: 'Store',align: 'center',sortable: false, value: 'tStore'}
      ],
      itemProductos: [],
    }
  },
  async mounted(){
    const response = await axios.get('http://www.app.bodeganet.cl/ApiPHP_ClientesPWA/public/api/inv/dash/productos/'+this.contrato);
    const datos=response.data;
    //console.log(datos);
    this.itemProductos=[];
    if (datos[0].existe=='si'){
      datos.forEach(element => {
        this.itemProductos.push({
          tBodega:element.Bodega,
          tCategorias:new Intl.NumberFormat("de-DE").format(parseFloat(element.Categorias)), 
          tProductos:new Intl.NumberFormat("de-DE").format(parseFloat(element.Productos)), 
          tMvto:new Intl.NumberFormat("de-DE").format(parseFloat(element.Movimientos)),
          tStore:new Intl.NumberFormat("de-DE").format(parseFloat(element.Store)),
          numStore:element.Store})        
      });
    }     
  },
  methods: {
    ColorStore(numStore) {
      if (parseInt(numStore) < 25) return 'red'
      else if (parseInt(numStore) < 50) return 'orange'
      else return 'green'
    }
  }
}
</script>