<template>
    <div>
        <v-card >
            <v-card-title class="blue-grey white--text">
                <v-row>
                    <v-col cols="6" md="6">VENTA</v-col>
                    <v-col cols="6" md="6">
                        <v-row class="mt-2 mr-2" justify="end">
                            <span class="text-caption">Suc.:<strong class="text-body-2 ml-2">{{txtSucursal}}</strong></span>
                        </v-row>
                    </v-col>
                </v-row>
            </v-card-title>
            <v-card-text>
                    <v-container>
                        <v-row>
                            <v-col cols="8" md="2">
                                <v-text-field class='text-caption' v-model="orden" label="Numero Cotiza" :disabled="disabledOrden" :filled="filledOrden" outlined dense required></v-text-field>
                            </v-col>
                            <v-col cols="4" md="1">
                                <v-btn @click="verOrden(orden)" class="mt-1" small color="teal" dark rounded><v-icon small>search</v-icon></v-btn>
                            </v-col>
                            <v-col cols="8" md="2">
                                <v-text-field class='text-caption' v-model="rut" label="Rut Cliente" dense outlined filled disabled></v-text-field>
                            </v-col>
                            <v-col cols="12" md="4">
                                <v-text-field class='text-caption' v-model="razon" label="Nombre" dense outlined filled disabled></v-text-field>
                            </v-col>
                        </v-row>
                        <v-divider></v-divider>
                        <v-row>
                            <v-col cols="12" md="9">
                                <v-data-table :headers="headers" :items="items" :page.sync="page" :items-per-page="itemsPerPage" hide-default-footer class="elevation-1 text-size col-color" @page-count="pageCount = $event">
                                    <template v-slot:item.stock="{ item }">
                                        <v-icon v-if="item.stock=='si'" small class="text green--text">check</v-icon>
                                        <v-icon v-if="item.stock=='no'" small class="text red--text">clear</v-icon>
                                    </template>
                                </v-data-table>
                                <v-pagination v-model="page" :length="pageCount"></v-pagination>
                            </v-col>
                            <v-col cols="12" md="3">
                                <v-alert class='text-caption' dense text border="left" color="grey">Neto ($):{{neto.toLocaleString()}}</v-alert> 
                                <v-alert class='text-caption' dense text border="left" color="grey">Iva ({{impuesto}}%): {{iva}}</v-alert>
                                <v-alert class='text-caption' dense text border="left" color="red">Total ($): {{total.toLocaleString()}}</v-alert>
                            </v-col>
                            <v-col cols="12" md="2">
                                <v-select class='text-caption' v-model="dte" :items="itemDTE" label="Tipo Documento" item-text="doc" item-value="id"></v-select>
                            </v-col>
                            <v-col cols="12" md="2">
                                <v-select class='ml-2 text-caption' v-model="pago" :items="itemPAGO" label="Forma Pago" item-text="forma" item-value="id"></v-select>
                            </v-col>
                            <v-col cols="12" md="2">
                                <v-menu v-model="menu1" :close-on-content-click="false" :nudge-right="40" transition="scale-transition" offset-y min-width="auto">
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field class="mt-3" v-model="vencimiento" label="Vencimiento" prepend-icon="event" readonly v-bind="attrs" v-on="on" dense outlined></v-text-field>
                                    </template>
                                    <v-date-picker v-model="vencimiento" @input="menu1 = false"></v-date-picker>
                                </v-menu>
                            </v-col>
                             <v-col cols="12" md="3">
                                <v-text-field class='text-caption' v-model="envio" label="Envio Digital" dense filled></v-text-field>
                            </v-col>
                            <v-col cols="12" md="3">
                                <div class="mt-5 text-right">
                                    <v-btn @click="newVenta()" small color="teal" dark rounded><v-icon small>add_circle</v-icon><span class='text-btn'>Nuevo</span></v-btn>
                                    <!-- <v-btn v-if="btnGrabar===true" @click="outDTE(dte,pago)" class="ml-2" small color="teal" dark rounded><v-icon small>save</v-icon><span class='text-btn'>Grabar</span></v-btn> -->
                                    <v-btn v-if="btnGrabar===true" @click="inDTE(dte,pago)" class="ml-2" small color="teal" dark rounded><v-icon small>save</v-icon><span class='text-btn'>Enviar DTE</span></v-btn>
                                    <v-btn v-else class="ml-2" small color="grey" dark rounded><v-icon small>save</v-icon><span class='text-btn'>Enviar DTE</span></v-btn>
                                    <a  v-if="btnPrt===true" target="_blank" :href="html" class="ml-2"><v-icon color="teal">print</v-icon></a>
                                    <a  v-else href="javascript:void(0)" class="ml-3"><v-icon class="grey--text">print</v-icon></a>
                                    <!-- <a  class="ml-2" v-if="descargaCSV===false" href="javascript:void(0)" @click="fileCSV()"><img valign="middle" src="@/assets/csv.png" width="16px" height="20px"/></a>
                                    <a  class="ml-2" v-else :href="rutaCSV"><v-icon color="teal">cloud_download</v-icon></a> -->
                                </div>
                            </v-col>
                        </v-row>
                    </v-container>
            </v-card-text>
            <v-card-actions style="margin-top:-35px">
            </v-card-actions>
        </v-card>
        <snack :Msg="Msg" :color="color" :msgbox="msgbox"/>
        <dialogo :loading="loading"  />        
    </div>
</template>
<style scoped>
.text-size >>> td {
    font-size: 9pt !important;
    height: 25px !important;
    /*background-color: lightyellow;*/ 
}
.col-color >>> th {
    background-color: lightgrey;
}
</style>
<script>
import snack from '@/components/snack.vue';
import dialogo from '@/components/dialogo.vue';
import axios from 'axios';
export default {
    name:'Venta', 
    components:{dialogo, snack},
    props: ['usu','contrato'],   
    data(){
        return{
            //contrato:'123',
            loading:false,
            msgbox:false,
            color:'',
            Msg:'',
            disabledOrden:false,
            filledOrden:false,
            btnGrabar:false,
            btnPrt:false,
            html:'',
            orden:'',
            entidad:'',
            rut:'',
            razon:'',
            direccion:'',
            comuna:'',
            ciudad:'',
            giro:'',
            fecha:'',
            hoy:'',
            neto:0,
            impuesto:'',
            iva:0,
            total:0,
            dte:'',
            pago:'',
            page: 1,
            pageCount: 0,
            itemsPerPage: 4,
            headers: [
                { text: 'Codigo', align: 'start', sortable: true, value: 'codigo' },
                { text: 'Producto', align: 'start', sortable: false, value: 'producto' },
                { text: 'Precio', align: 'center', sortable: false, value: 'precio' },
                { text: 'cantidad', align: 'center', sortable: false, value: 'cantidad' },
                { text: 'Bodega', align: 'center', sortable: false, value: 'bodega' },
                { text: 'Stock', align: 'center', sortable: false, value: 'stock' },
            ],
            items: [],
            itemDTE:[
                {id:'22',doc:'Boleta'},
                {id:'5',doc:'Factura'},
            ],
            itemPAGO:[],
            OutID:0,
            descargaCSV:false,
            rutaCSV:'',   
            envio:'',
            nStock:0,
            txtStock:'si',
            nSucursal:0,
            txtSucursal:'',
            menu1:false,
            vencimiento:(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
            credito:'',
        }
    },
    mounted(){
        this.newVenta();
        this.buscarSucursal();
        this.cargaPagos('1-9');
    },
    methods:{
        async cargaPagos(xRut){
            try {
                var auth='NO';
                const res = await axios.get('http://www.app.bodeganet.cl/ApiPHP_ClientesPWA/public/api/inv/clientes/'+this.contrato+'/'+ xRut);
                const dat=res.data;
                if (dat[0].existe=='si'){ auth=dat[0].Credito };            
                const response = await axios.get('http://www.app.bodeganet.cl/ApiPHP_ClientesPWA/public/api/inv/DTE/forma/pago/'+this.contrato);
                const datos=response.data;
                if (datos[0].existe=='si'){
                    this.itemPAGO=[];
                    datos.forEach(element => {
                        if(element.CodigoBsale=='4'){
                            if(auth=='SI'){
                                this.itemPAGO.push({
                                    'id':element.CodigoBsale,
                                    'forma':element.Descripcion
                                })
                            }
                        } else {
                            this.itemPAGO.push({
                                'id':element.CodigoBsale,
                                'forma':element.Descripcion
                            })
                        }
                    });
                }                
            } catch (error) {
                console.log(error)
            }
        },
        async buscarSucursal(){
            const response = await axios.get('http://www.app.bodeganet.cl/ApiPHP_ClientesPWA/public/api/inv/usuarios/'+this.contrato+'/'+this.usu);
            const datos=response.data;
            if (datos[0].existe=='si'){
                if (datos[0].Sucursal!=0){
                    this.nSucursal=datos[0].Sucursal;
                    const response1 = await axios.get('http://www.app.bodeganet.cl/ApiPHP_ClientesPWA/public/api/inv/sucursales/'+this.contrato);
                    const datos1=response1.data;
                    if (datos1[0].existe=='si'){
                        datos1.forEach(element => {
                            if(element.ID==this.nSucursal){
                                this.txtSucursal=element.Nombre                            
                            }
                        });
                    } else {  
                        this.txtSucursal='Sin Sucursal';
                        this.nSucursal=0;
                    }
                } else {
                    this.txtSucursal='Sin Sucursal';
                    this.nSucursal=0;
                }
            } else { 
                this.txtSucursal='Sin Sucursal';
                this.nSucursal=0;
            }
        },
        async fileCSV(){
            if (this.btnPrt===true){
                this.loading=true;
                const response = await axios.get('http://www.app.bodeganet.cl/ApiPHP_ClientesPWA/public/api/inv/fileCsv/venta/'+this.contrato+'/'+this.OutID+'/'+this.entidad);
                const datos=response.data;
                //console.log(datos);
                if (datos=='si'){
                    this.descargaCSV=true;
                    this.rutaCSV='http://www.app.bodeganet.cl/ApiPHP_ClientesPWA/public/buzon/'+this.contrato+'/'+this.OutID+'.csv';
                }
                this.loading=false;
            }
        },
        showSnack(){
            setTimeout(()=>{this.msgbox=false},3000)
        },
        clsInput(){
            this.rut='';
            this.razon='';
            this.direccion='';
            this.comuna='';
            this.ciudad='';
            this.giro='';
            this.fecha='';
            this.hoy='';
            this.neto=0;
            this.iva=0;
            this.total=0;
            this.impuesto='';
            this.dte='';
            this.pago='';
            this.items=[];
            this.btnPrt=false;
            this.html='';
            this.OutID=0;
            this.entidad='';
            this.descargaCSV=false;
            this.rutaCSV='';
            this.envio='';   
            this.nStock=0;
            this.txtStock='si';
            this.credito='';
        },
        newVenta(){
            this.orden='';
            this.disabledOrden=false;
            this.filledOrden=false;
            this.btnGrabar=false;
            this.clsInput()
        },
        async verOrden(nOrden){
            this.clsInput();
            this.loading=true;
            const response = await axios.get('http://www.app.bodeganet.cl/ApiPHP_ClientesPWA/public/api/inv/orden/'+this.contrato+'/'+nOrden);
            const datos=response.data;
            if (datos[0].existe=='si'){ 
                this.disabledOrden=true;
                this.filledOrden=true;
                this.btnGrabar=true;
                this.entidad=datos[0].Entidad;
                this.rut=datos[0].Rut;
                this.razon=datos[0].Nombre;
                this.direccion=datos[0].Direccion;
                this.comuna=datos[0].Comuna;
                this.ciudad=datos[0].Ciudad;
                this.giro=datos[0].Observacion;
                this.fecha=datos[0].Fecha;
                this.hoy=datos[0].Hoy;
                this.impuesto=datos[0].Iva;
                this.credito=datos[0].Credito;
                this.cargaPagos(this.rut);
                this.items=[];
                let nTotal=0;

                //this.hoy='2023-08-30';
                
                for (let index = 0; index < datos.length; index++) {
                    const element = datos[index];
                    this.nStock=await this.totalStock(element.Bodega,element.Sku);
                    if (parseInt(this.nStock)>=parseInt(element.Cantidad)){
                        this.items.push({
                            'codigo':element.Sku,
                            'producto':element.Producto,
                            'precio':new Intl.NumberFormat("de-DE").format(parseFloat(element.Precio)),
                            'unitario':element.Precio,
                            'cantidad':element.Cantidad,
                            'bodega':element.Bodega,
                            'stock':'si'
                        });
                    } else {
                        this.txtStock="no";
                        this.items.push({
                            'codigo':element.Sku,
                            'producto':element.Producto,
                            'precio':new Intl.NumberFormat("de-DE").format(parseFloat(element.Precio)),
                            'unitario':element.Precio,
                            'cantidad':element.Cantidad,
                            'bodega':element.Bodega,
                            'stock':'no'
                        });
                    }
                    nTotal=nTotal+parseInt(element.Total)                    
                };
                // datos.forEach(element => {
                //     this.items.push({
                //         'codigo':element.Sku,
                //         'producto':element.Producto,
                //         'precio':new Intl.NumberFormat("de-DE").format(parseFloat(element.Precio)),
                //         'unitario':element.Precio,
                //         'cantidad':element.Cantidad,
                //         'bodega':element.Bodega
                //     });
                //     nTotal=nTotal+parseInt(element.Total)       
                // });
                let nIva=Math.round((nTotal*parseInt(datos[0].Iva))/100);
                let nNeto=nTotal;
                nTotal=nTotal+nIva;
                this.neto=nNeto;
                this.iva=nIva;
                this.total=nTotal;
            } else {
                this.Msg='Orden no Existe o con DTE'
                this.msgbox=true;
                this.color='red';
                this.showSnack();
            }
            this.loading=false;
        },
        async totalStock(b,s){
            const response = await axios.get('http://www.app.bodeganet.cl/ApiPHP_ClientesPWA/public/api/inv/stock/bodegas/producto/total/'+ this.contrato +'/'+ b + '/' + s);
            const datos=response.data;
            if (datos[0].existe=='si'){ 
                 return datos[0].Total;
            } else { return 0 }
        },
        async outDTE(selectDTE,selectPago){
            if (parseInt(this.total)!=0){
                if (selectDTE!=0 && selectPago!=0){
                    this.loading=true;
                    this.btnGrabar=false;
                    //obtener ID nuevo del dte
                    const response = await axios.get('http://www.app.bodeganet.cl/ApiPHP_ClientesPWA/public/api/inv/DTE/id/new/'+this.contrato);
                    const res = response.data;
                    if (res[0].existe=='si'){ 
                        this.OutID=parseInt(res[0].ID)+1; 
                        this.html='http://www.app.bodeganet.cl/ApiPHP_ClientesPWA/public/api/inv/prt/venta/'+this.contrato+'/'+this.OutID+'/'+this.entidad;            
                        const paramsDTE={
                            'contrato':this.contrato,
                            'numero':this.OutID,
                            'tipoDte':selectDTE,
                            'tipoPago':selectPago,
                            'neto':this.neto,
                            'iva':this.iva,
                            'total':this.total,
                            'fecha':this.hoy,
                            'orden':this.orden,
                            'rutaPDF':this.html,
                            'rutaXML':this.html,
                            'usuario':this.usu
                        };     
                        await this.saveDTE(paramsDTE);
                    } else {  
                        this.OutID=0 
                        this.Msg='Sin ID Devolución'
                        this.msgbox=true;
                        this.color='red';
                        this.showSnack();
                    };
                } else {
                    this.Msg='Seleccione DTE y Pago'
                    this.msgbox=true;
                    this.color='red';
                    this.showSnack();
                }
            } else {
                this.Msg='Sin Devolución'
                this.msgbox=true;
                this.color='red';
                this.showSnack();
            }
            this.loading=false;
        },
        async inDTE(selectDTE,selectPago){
            if(this.nSucursal!=0){
                if(this.txtStock!="si"){
                    this.Msg='Productos sin Stock';
                    this.msgbox=true;
                    this.color='red';
                    this.showSnack();
                } else {
                    this.loading=true;
                    if (selectDTE!=0 && selectPago!=0){
                        this.btnGrabar=false;
                        // obtenemos el token del contrato
                        let valToken='';
                        const resToken = await axios.get('http://www.app.bodeganet.cl/ApiPHP_ClientesPWA/public/api/inv/token/access/'+this.contrato);
                        const respuesta = resToken.data;
                        if (respuesta[0].existe=='si'){ 
                            valToken=respuesta[0].Token;
                        } else { console.log('Token Invalido') }
                        // fecha cotizacion formato Bsale
                        let intFecha=Date.parse(this.fecha);
                        let strFecha=intFecha.toString();
                        strFecha=strFecha.substring(0,10);
                        const numFecha=parseInt(strFecha);
                        // fecha actual formato Bsale
                        let intHoy=Date.parse(this.hoy);
                        let strHoy=intHoy.toString();
                        strHoy=strHoy.substring(0,10);
                        const numHoy=parseInt(strHoy);
                        // fecha vencimiento formato Bsale
                        let intVence=Date.parse(this.vencimiento);
                        let strVence=intVence.toString();
                        strVence=strVence.substring(0,10);
                        const numVence=parseInt(strVence);
                        // creamos json Bsale
                        let pCliente={
                            "code": this.rut,
                            "city": this.ciudad,
                            "company": this.razon,
                            "municipality": this.comuna,
                            "activity": this.giro,
                            "address": this.direccion
                        };
                        let pDetalle=[];
                        this.items.forEach(element => {
                            pDetalle.push({
                                //"variantId":1,
                                "netUnitValue":parseInt(element.unitario),
                                "quantity": parseInt(element.cantidad),
                                //"taxId": "[1,2]",
                                "comment": element.producto,
                                //"discount": 0,
                                "taxes": [
                                    {
                                    "code": 14,
                                    "percentage": 19
                                    }
                                ]
                            })        
                        });
                        let pPago=[
                            {
                                "paymentTypeId": selectPago,
                                "amount": this.total,
                                "recordDate": numHoy
                            }
                        ];
                        let pReferencia=[
                            {
                                "number": this.orden,
                                "referenceDate": numFecha,
                                "reason": "Orden de Compra "+this.orden,
                                "codeSii": 801
                            }
                        ];
                        //console.log(selectDTE);
                        var emitDTE;
                        if(selectDTE!=22){ // factura
                            emitDTE=
                            {
                                "documentTypeId": selectDTE,
                                "officeId": 1,
                                "emissionDate": numHoy,
                                "expirationDate": numVence,
                                "declareSii": 1,
                                //"priceListId": 18,
                                "client": pCliente,
                                "details": pDetalle,
                                "payments": pPago,
                                "references": pReferencia
                            }
                        } else { // boleta
                            emitDTE=
                            {
                                "documentTypeId": selectDTE,
                                "officeId": 1,
                                "emissionDate": numHoy,
                                "expirationDate": numHoy,
                                "declareSii": 1,
                                //"priceListId": 18,
                                "details": pDetalle
                                //"payments": pPago,
                            }
                        }
                        // consumir api Bsale para crear DTE
                        let resDTE =[];
                        console.log(valToken);
                        try {
                            const response = await axios.post('https://api.bsale.cl/v1/documents.json', emitDTE , {headers : {"Accept":"application/json","content-type":"application/json","access_token":valToken}});
                            resDTE = response.data;
                        } catch (error) {
                            console.log (error);
                        }
                        if (resDTE.length!=0){
                            this.envioDTE(this.envio,resDTE.urlPdf,resDTE.urlXml);
                            this.html=resDTE.urlPdf;
                            this.btnPrt=true;
                            this.OutID=0;
                            this.OutID=resDTE.number;                    
                            const paramsDTE={
                                'contrato':this.contrato,
                                'numero':resDTE.number,
                                'tipoDte':selectDTE,
                                'tipoPago':selectPago,
                                'neto':this.neto,
                                'iva':this.iva,
                                'total':this.total,
                                'fecha':this.hoy,
                                'vencimiento':this.vencimiento,
                                'orden':this.orden,
                                'rutaPDF':resDTE.urlPdf,
                                'rutaXML':resDTE.urlXml,
                                'usuario':this.usu,
                                'sucursal':this.nSucursal
                            };                    
                            await this.saveDTE(paramsDTE);
                        } else {
                            this.Msg='DTE no enviado'
                            this.msgbox=true;
                            this.color='red';
                            this.showSnack();
                        }
                        //console.log(res);
                    } else {
                        this.Msg='Seleccione DTE y Pago'
                        this.msgbox=true;
                        this.color='red';
                        this.showSnack();
                    }
                    this.loading=false;
                }
            } else {
                this.Msg='Venta Sin Sucursal'
                this.msgbox=true;
                this.color='red';
                this.showSnack();
            }
        },
        async saveDTE(parametrosDTE){
            const response = await axios.post('http://www.app.bodeganet.cl/ApiPHP_ClientesPWA/public/api/inv/DTE/nueva',parametrosDTE);
            const datos=response.data;
            //console.log(parametrosDTE);
            if (datos=='si'){ 
                await this.updateReferencia();
            } else {
                this.Msg='DTE enviado sin registro Local'
                this.msgbox=true;
                this.color='warning';
                this.showSnack();
            }
        },
        async updateReferencia(){
            var parametros0={'numero': this.OutID};
            const response0 = await axios.put('http://www.app.bodeganet.cl/ApiPHP_ClientesPWA/public/api/inv/referencia/actualiza/'+this.contrato+'/'+this.orden+'/'+this.entidad,parametros0);
            const datos0=response0.data;
            //console.log(datos0);
             if (datos0=='si'){
                await this.UpdateStock();
             } else {
                this.Msg='DTE enviado sin Referencia';
                this.msgbox=true;
                this.color='warning';
                this.showSnack();
             }
        },
        async UpdateStock(){
            let t=0;
            if (this.items.length!=0) {
                for (var i = 0; i < this.items.length; i++) {
                    var parametros1={
                            'contrato': this.contrato,
                            'sku': this.items[i].codigo,
                            'cantidad': this.items[i].cantidad,
                            'signo': '0-',
                            'bodega': this.items[i].bodega,
                            'usuario': this.usu,
                            'observa': '',
                            'origen':'Venta'};
                    const response1 = await axios.post('http://www.app.bodeganet.cl/ApiPHP_ClientesPWA/public/api/inv/stock',parametros1);
                    const datos1=response1.data;
                    if (datos1=='si'){ t++ }
                };
                if (t!=this.items.length) {
                    this.Msg='DTE enviado sin actualizar Stock';
                    this.msgbox=true;
                    this.color='warning';
                    this.showSnack();
                } else {
                    this.btnPrt=true;
                    this.Msg='DTE exitoso';
                    this.msgbox=true;
                    this.color='success';
                    this.showSnack();
                }
            }
        },
        async envioDTE(datoEnvio,datoPDF,datoXML){
            if (datoEnvio.trim()!=''){
                let InstFormData = new FormData();
                InstFormData.append('email' , datoEnvio);
                InstFormData.append('pdf' , datoPDF);
                InstFormData.append('xml' , datoXML);
                const response= await axios.post('http://www.app.bodeganet.cl/ApiPHP_ClientesPWA/public/api/server/sender', InstFormData , {headers : {'content-type': 'multipart/form-data'}});
                //console.log(response.data)
            }
        },
    }
}
</script>