<template>
    <div>
    <h2 class="mt-1 ml-15 hidden-sm-and-down grey--text">Reportes</h2> <!-- Full Screen -->
    <h3 class="mt-1 hidden-sm-and-up grey--text">Reportes</h3> <!-- Mobile Screen -->
        <v-card class="mt-5" dark>
            <v-card-subtitle class="blue-grey">
                <v-row class="mt-1">
                    <v-col cols="12" md="3">
                        <v-select @change="limpiaTable()" class='text-caption' v-model="tipo" :items="tipoReporte" label="Tipo Reporte" dense outlined></v-select>
                    </v-col>
                    <v-col cols="6" md="3">
                        <v-menu v-model="menu1" :close-on-content-click="false" :nudge-right="40" transition="scale-transition" offset-y min-width="auto">
                            <template v-slot:activator="{ on, attrs }">
                                <v-text-field v-model="date1" label="Desde" prepend-icon="mdi-calendar" readonly v-bind="attrs" v-on="on" dense outlined :disabled="disabledfecha"></v-text-field>
                            </template>
                            <v-date-picker v-model="date1" @input="menu1 = false"></v-date-picker>
                        </v-menu>
                    </v-col>
                    <v-col cols="6" md="3">
                        <v-menu v-model="menu2" :close-on-content-click="false" :nudge-right="40" transition="scale-transition" offset-y min-width="auto">
                            <template v-slot:activator="{ on, attrs }">
                                <v-text-field v-model="date2" label="Hasta" prepend-icon="mdi-calendar" readonly v-bind="attrs" v-on="on" dense outlined :disabled="disabledfecha"></v-text-field>
                            </template>
                            <v-date-picker v-model="date2" @input="menu2 = false"></v-date-picker>
                        </v-menu>
                    </v-col>
                    <v-col cols="12" md="3">
                        <v-btn @click="verReporte(tipo)" class="mt-1" small color="white" rounded><v-icon small class="blue-grey--text">search</v-icon></v-btn>
                        <a v-if="descargaCSV===false" href="javascript:void(0)" @click="listaCSV(tipo)" style="text-decoration: none">
                            <v-btn class="mt-1 ml-2" small color="white" rounded><img valign="middle" src="@/assets/csv.png" width="16px" height="20px"/></v-btn>
                        </a>
                        <a v-else :href="descarga" style="text-decoration: none">
                            <v-btn class="mt-1 ml-2" small color="white" rounded><v-icon class="blue-grey--text">cloud_download</v-icon></v-btn>
                        </a>
                    </v-col>
                </v-row>
            </v-card-subtitle>
            <v-card-text>
                <v-data-table :search="likesearch" :headers="headers" :items="itemsResult" :page.sync="page" :items-per-page="itemsPerPage" hide-default-footer class="elevation-1 text-size" @page-count="pageCount = $event">
                    <template v-slot:item.ruta="{ item }">
                        <a target="_blank" :href="item.ruta"><v-icon class="white--text" small >print</v-icon></a>
                    </template>
                    <template v-slot:item.detalle="{ item }">
                        <v-btn @click="verDetalle(tipo,item)" dark text><v-icon class="white--text" small >description</v-icon></v-btn>
                    </template>
                </v-data-table>
            </v-card-text>
            <v-card-actions>
                <v-row>
                    <v-col cols="12" md="6">
                        <v-pagination v-model="page" :length="pageCount" :total-visible="10"></v-pagination>
                    </v-col>
                    <v-col cols="10" md="4">
                        <v-text-field class='ml-5 mr-2 text-caption' v-model="likesearch" label="Buscar Dato" dense required></v-text-field>
                    </v-col>
                </v-row>
            </v-card-actions>
        </v-card>
        <!-- dialogo detalle -->
        <v-dialog v-model="dialog" transition="dialog-bottom-transition" max-width="80%" persistent>
        <v-card>
            <v-card-title>
                <v-btn @click="dialog=false" color="red" small dark><v-icon small>close</v-icon>Close</v-btn>
            </v-card-title>
            <v-card-text>
                <v-data-table dark :search="likesearchDialog" :headers="headersDetalle" :items="itemsDetalle" :page.sync="pageDialog" :items-per-page="itemsPerPageDialog" hide-default-footer class="elevation-1 text-size" @page-count="pageCountDialog = $event">
                </v-data-table>
            </v-card-text>
            <v-card-actions>
                <v-row>
                    <v-col cols="12" md="6">
                        <v-pagination v-model="pageDialog" :length="pageCountDialog" :total-visible="10"></v-pagination>
                    </v-col>
                    <v-col cols="10" md="4">
                        <v-text-field class='ml-5 mr-2 text-caption' v-model="likesearchDialog" label="Buscar Producto" dense required></v-text-field>
                    </v-col>
                </v-row>
            </v-card-actions>
        </v-card>
        </v-dialog>
        <snack :Msg="Msg" :color="color" :msgbox="msgbox"/>
        <dialogo :loading="loading"/>
    </div>
</template>
<style scoped>
.text-size >>> td {
    font-size: 8pt !important;
    height: 25px !important;
    /* background-color: lightyellow; */
}
</style>
<script>
import snack from '@/components/snack.vue';
import dialogo from '@/components/dialogo.vue';
import axios from 'axios';
export default {
    name: 'Reportes',
    components:{dialogo, snack},
    props: ['usu','contrato'],
    data(){
        return{
            fullReport:false,
            loading:false,
            msgbox:false,
            color:'',
            Msg:'',
            tipo:'',
            tipoReporte:[],
            headers: [],
            items: [],
            headersDetalle: [],
            itemsDetalle: [],
            dialog:false,
            itemsResult: [],
            likesearch:'',
            page: 1,
            pageCount: 0,
            itemsPerPage: 8,
            likesearchDialog:'',
            pageDialog: 1,
            pageCountDialog: 0,
            itemsPerPageDialog: 10,
            menu1:false,
            date1: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
            menu2:false,
            date2: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
            descarga:'javascript:void(0)',
            descargaCSV:false,
            disabledfecha:false,
            //utilidades
            codigos:[],
            entradas:[],
            salidas:[],

            //Utilidad Final
            productos:[],
            movimientos:[],
            ganancias:[]
        }
    },
    async mounted(){
        await this.entrada();
    },
    methods: {
        async listaCSV(tipo){
            if (this.items.length!=0){
                await this.csvReporte(tipo);
                this.descargaCSV=true;
            }
        },
        limpiaTable(){
            this.headers= [];
            this.items= [];
            this.headersDetalle= [];
            this.itemsDetalle= [];
            this.itemsResult= [];
            this.descargaCSV=false;
            this.descarga='javascript:void(0)';
            //console.log(this.tipo);
             if(this.tipo=='Stock' || 
                this.tipo=='Proveedores' ||
                this.tipo=='Clientes' ||
                this.tipo=='Usuarios' ||
                this.tipo=='Credito Cobranza' ||
                this.tipo=='Lista de Precios') {
                    this.disabledfecha=true;
             } else { this.disabledfecha=false }
        },
        async entrada(){
            this.loading=true;
            const response = await axios.get('http://www.app.bodeganet.cl/ApiPHP_ClientesPWA/public/api/inv/usuarios/'+ this.contrato +'/'+ this.usu);
            const datos=response.data;
            this.tipoReporte=[];
            if (datos[0].existe=='si'){
                if (datos[0].Reporte!=0){
                    this.fullReport=true
                    this.tipoReporte.push('Stock');
                    //this.tipoReporte.push('Utilidad');
                    this.tipoReporte.push('Utilidades');
                    //this.tipoReporte.push('Ganancias');
                    this.tipoReporte.push('Credito Cobranza');
                    this.tipoReporte.push('Lista de Precios');
                } else {
                    this.fullReport=false
                };
                if(datos[0].Permiso_4_2!=0 || datos[0].Permiso_4_3!=0) {
                    this.tipoReporte.push('Productos')
                }; 
                if(datos[0].Permiso_3_1!=0) {
                    this.tipoReporte.push('Compras')
                };
                if(datos[0].Permiso_3_3!=0) {
                    this.tipoReporte.push('Cotizaciones')
                }; 
                if(datos[0].Permiso_3_2!=0) {
                    this.tipoReporte.push('Ventas')
                }; 
                if(datos[0].Permiso_3_4!=0) {
                    this.tipoReporte.push('Devoluciones')
                }; 
                if(datos[0].Permiso_6_1!=0) {
                    this.tipoReporte.push('Proveedores')
                }; 
                if(datos[0].Permiso_6_2!=0) {
                    this.tipoReporte.push('Clientes')
                }; 
                if(datos[0].Permiso_6_3!=0) {
                    this.tipoReporte.push('Usuarios')
                }; 
            }
            this.loading=false; 
        },
        showSnack(){
            setTimeout(()=>{this.msgbox=false},3000)
        },
        async verDetalle(tipo,array){
            this.headersDetalle=[];
            this.itemsDetalle=[];
            let response;
            let datos;
            switch (tipo) {
                case 'Compras':
                    response = await axios.get('http://www.app.bodeganet.cl/ApiPHP_ClientesPWA/public/api/inv/orden/entidad/C/'+this.contrato+'/'+array.numero+'/'+array.entidad);
                    datos=response.data;
                    if (datos[0].existe=='si'){
                        this.headersDetalle=[
                            { text: 'Sku', align: 'start', sortable: true, value: 'sku' },
                            { text: 'Producto', align: 'start', sortable: true, value: 'producto' },
                            { text: 'Precio', align: 'center', sortable: true, value: 'precio' },
                            { text: 'cantidad', align: 'center', sortable: true, value: 'cantidad' },
                            { text: 'Bodega', align: 'center', sortable: true, value: 'bodega' },
                            { text: 'Observacion', align: 'start', sortable: true, value: 'observa' },
                        ];
                        datos.forEach(element => {
                            this.itemsDetalle.push({
                                'sku':element.Sku,
                                'producto':element.Producto,
                                'precio':new Intl.NumberFormat("de-DE").format(parseFloat(element.Precio)),
                                'cantidad':new Intl.NumberFormat("de-DE").format(parseFloat(element.Cantidad)),
                                'bodega':element.Bodega,
                                'observa':element.Observa,
                            })        
                        });
                    }
                break
                case 'Cotizaciones':
                    //console.log(array);
                    response = await axios.get('http://www.app.bodeganet.cl/ApiPHP_ClientesPWA/public/api/inv/orden/entidad/V/'+this.contrato+'/'+array.orden+'/'+array.entidad);
                    datos=response.data;
                    if (datos[0].existe=='si'){
                        this.headersDetalle=[
                            { text: 'Sku', align: 'start', sortable: true, value: 'sku' },
                            { text: 'Producto', align: 'start', sortable: true, value: 'producto' },
                            { text: 'Precio', align: 'center', sortable: true, value: 'precio' },
                            { text: 'cantidad', align: 'center', sortable: true, value: 'cantidad' },
                            { text: 'Bodega', align: 'center', sortable: true, value: 'bodega' },
                            { text: 'Observacion', align: 'start', sortable: true, value: 'observa' },
                        ];
                        datos.forEach(element => {
                            this.itemsDetalle.push({
                                'sku':element.Sku,
                                'producto':element.Producto,
                                'precio':new Intl.NumberFormat("de-DE").format(parseFloat(element.Precio)),
                                'cantidad':new Intl.NumberFormat("de-DE").format(parseFloat(element.Cantidad)),
                                'bodega':element.Bodega,
                                'observa':element.Observa,
                            })        
                        });
                    }
                break
                case 'Ventas':
                    //console.log(array);
                    response = await axios.get('http://www.app.bodeganet.cl/ApiPHP_ClientesPWA/public/api/inv/orden/entidad/V/'+this.contrato+'/'+array.orden+'/'+array.entidad);
                    datos=response.data;
                    if (datos[0].existe=='si'){
                        this.headersDetalle=[
                            { text: 'Sku', align: 'start', sortable: true, value: 'sku' },
                            { text: 'Producto', align: 'start', sortable: true, value: 'producto' },
                            { text: 'Precio', align: 'center', sortable: true, value: 'precio' },
                            { text: 'cantidad', align: 'center', sortable: true, value: 'cantidad' },
                            { text: 'Bodega', align: 'center', sortable: true, value: 'bodega' },
                        ];
                        datos.forEach(element => {
                            this.itemsDetalle.push({
                                'sku':element.Sku,
                                'producto':element.Producto,
                                'precio':new Intl.NumberFormat("de-DE").format(parseFloat(element.Precio)),
                                'cantidad':new Intl.NumberFormat("de-DE").format(parseFloat(element.Cantidad)),
                                'bodega':element.Bodega,
                            })        
                        });
                    }
                break
                case 'Devoluciones':
                    response = await axios.get('http://www.app.bodeganet.cl/ApiPHP_ClientesPWA/public/api/inv/orden/entidad/D/'+this.contrato+'/'+array.numero+'/'+array.entidad);
                    datos=response.data;
                    if (datos[0].existe=='si'){
                        this.headersDetalle=[
                            { text: 'Sku', align: 'start', sortable: true, value: 'sku' },
                            { text: 'Producto', align: 'start', sortable: true, value: 'producto' },
                            { text: 'Precio', align: 'center', sortable: true, value: 'precio' },
                            { text: 'cantidad', align: 'center', sortable: true, value: 'cantidad' },
                            { text: 'Bodega', align: 'center', sortable: true, value: 'bodega' },
                        ];
                        datos.forEach(element => {
                            this.itemsDetalle.push({
                                'sku':element.Sku,
                                'producto':element.Producto,
                                'precio':new Intl.NumberFormat("de-DE").format(parseFloat(element.Precio)),
                                'cantidad':new Intl.NumberFormat("de-DE").format(parseFloat(element.Cantidad)),
                                'bodega':element.Bodega,
                            })        
                        });
                    }
                break
            }
            this.dialog=true;
        },
        async csvReporte(tipo){
            this.loading=true;
            this.descarga='javascript:void(0)';
            var date = new Date();
            const formatDate = (current_datetime)=>{
                let formatted_date = current_datetime.getFullYear() + "" + (current_datetime.getMonth() + 1) + "" + current_datetime.getDate() + "" + current_datetime.getHours() + "" + current_datetime.getMinutes() + "" + current_datetime.getSeconds();
                return formatted_date;
            };
            const nameFile = tipo+'_'+formatDate(date);
            let csv;
            csv=[];
            let params;
            let response;
            //console.log(tipo);
            switch (tipo) {
                case 'Utilidades':
                    //titulo
                    csv.push({
                        'Fecha':'Fecha',
                        'Sku':'Sku',
                        'Producto':'Producto',
                        'Cantidad':'Cantidad',
                        'Precio Compra':'PrecioCompra',
                        'Precio Venta':'PrecioVenta',
                        'Utilidad':'Utilidad',
                        'Bodega':'Bodega',
                        'Origen':'Origen'
                    });
                    //datos
                    this.itemsResult.forEach(element => {
                        csv.push({
                            'Fecha':element.Fecha,
                            'Sku':element.Sku,
                            'Producto':element.Producto,
                            'Cantidad':element.Cantidad,
                            'PrecioCompra':element.PrecioCompra,
                            'PrecioVenta':element.PrecioVenta,
                            'Utilidad':element.Utilidad,
                            'Bodega':element.Bodega,
                            'Origen':element.Origen
                        })
                    });
                    params = {'datos':csv,'contrato':this.contrato,'archivo':nameFile};
                    response = await axios.post('http://www.app.bodeganet.cl/ApiPHP_ClientesPWA/public/api/inv/csv/nuevo',params);
                    if (response.data=='si'){
                        this.descarga='https://www.app.bodeganet.cl/ApiPHP_ClientesPWA/public/buzon/'+this.contrato+'/'+nameFile+'.csv';
                    }
                break
                case 'Ganancias':
                    //titulo
                    csv.push({
                        'Fecha':'Fecha',
                        'Sku':'Sku',
                        'Producto':'Producto',
                        'Cantidad':'Cantidad',
                        'Precio Compra':'PrecioCompra',
                        'Precio Venta':'PrecioVenta',
                        'Utilidad':'Utilidad',
                        'Bodega':'Bodega',
                        'Origen':'Origen'
                    });
                    //datos
                    this.itemsResult.forEach(element => {
                        csv.push({
                            'Fecha':element.Fecha,
                            'Sku':element.Sku,
                            'Producto':element.Producto,
                            'Cantidad':element.Cantidad,
                            'PrecioCompra':element.PrecioCompra,
                            'PrecioVenta':element.PrecioVenta,
                            'Utilidad':element.Utilidad,
                            'Bodega':element.Bodega,
                            'Origen':element.Origen
                        })
                    });
                    params = {'datos':csv,'contrato':this.contrato,'archivo':nameFile};
                    response = await axios.post('http://www.app.bodeganet.cl/ApiPHP_ClientesPWA/public/api/inv/csv/nuevo',params);
                    if (response.data=='si'){
                        this.descarga='https://www.app.bodeganet.cl/ApiPHP_ClientesPWA/public/buzon/'+this.contrato+'/'+nameFile+'.csv';
                    }
                break
                case 'Utilidad':
                    //titulo
                    csv.push({
                        'fecha':'Fecha',
                        'sku':'Sku',
                        'producto':'Producto',
                        'cantidad':'Cantidad',
                        'compra':'Compra',
                        'venta':'Venta',
                        //'devolucion':'Devolucion',
                        'utilidad':'Utilidad',
                        'sucursal':'Sucursal'
                    });
                    //datos
                    this.itemsResult.forEach(element => {
                        csv.push({
                            'fecha':element.fecha,
                            'sku':element.sku,
                            'producto':element.producto,
                            'cantidad':element.cantidad,
                            'compra':element.compra,
                            'venta':element.venta,
                            //'devolucion':element.devolucion,
                            'utilidad':element.utilidad,
                            'sucursal':element.sucursal
                        })
                    });
                    params = {'datos':csv,'contrato':this.contrato,'archivo':nameFile};
                    response = await axios.post('http://www.app.bodeganet.cl/ApiPHP_ClientesPWA/public/api/inv/csv/nuevo',params);
                    if (response.data=='si'){
                        this.descarga='https://www.app.bodeganet.cl/ApiPHP_ClientesPWA/public/buzon/'+this.contrato+'/'+nameFile+'.csv';
                    }
                break
                case 'Stock':
                    //titulo
                    csv.push({
                        'sku':'Sku',
                        'producto':'Producto',
                        'bodega':'Bodega',
                        'stock':'Stock',
                        'u12m':'U12M',
                        'pu12m':'PU12M',
                        'u30d':'U30D',
                        'u90d':'U90D',
                        'p3m':'P3M',
                        'critico':'Critico'
                    });
                    //datos
                    this.itemsResult.forEach(element => {
                        csv.push({
                            'sku':element.sku,
                            'producto':element.producto,
                            'bodega':element.bodega,
                            'stock':element.stock,
                            'u12m':element.u12m,
                            'pu12m':element.pu12m,
                            'u30d':element.u30d,
                            'u90d':element.u90d,
                            'p3m':element.p3m,
                            'critico':element.critico
                        })
                    });
                    params = {'datos':csv,'contrato':this.contrato,'archivo':nameFile};
                    response = await axios.post('http://www.app.bodeganet.cl/ApiPHP_ClientesPWA/public/api/inv/csv/nuevo',params);
                    if (response.data=='si'){
                        this.descarga='https://www.app.bodeganet.cl/ApiPHP_ClientesPWA/public/buzon/'+this.contrato+'/'+nameFile+'.csv';
                    }
                break
                case 'Productos':
                    //titulo
                    csv.push({
                        'fecha':'Fecha',
                        'sku':'Sku',
                        'producto':'Producto',
                        'movimiento':'Movimiento',
                        'entidad':'Entidad',
                        'cantidad':'Cantidad',
                        'precio':'Precio',
                        'bodega':'Bodega',
                        'usuario':'Usuario',
                        'observa':'Observa'
                    });
                    //datos
                    this.itemsResult.forEach(element => {
                        csv.push({
                            'fecha':element.fecha,
                            'sku':element.sku,
                            'producto':element.producto,
                            'movimiento':element.movimiento,
                            'entidad':element.entidad,
                            'cantidad':element.cantidad,
                            'precio':element.precio,
                            'bodega':element.bodega,
                            'usuario':element.usuario,
                            'observa':element.observa
                        })
                    });
                    params = {'datos':csv,'contrato':this.contrato,'archivo':nameFile};
                    response = await axios.post('http://www.app.bodeganet.cl/ApiPHP_ClientesPWA/public/api/inv/csv/nuevo',params);
                    if (response.data=='si'){
                        this.descarga='https://www.app.bodeganet.cl/ApiPHP_ClientesPWA/public/buzon/'+this.contrato+'/'+nameFile+'.csv';
                    }
                break
                case 'Compras':
                    //titulo
                    csv.push({
                        'emision':'Emision',
                        'fecha':'Ingreso',
                        'vencimiento':'Vencimiento',
                        'proveedor':'Proveedor',
                        'numero':'Numero',
                        'pago':'Pago',
                        'productos':'Productos',
                        'total':'Total',
                        'exenta':'Exenta',
                        'usuario':'Usuario'
                    });
                    //datos
                    this.itemsResult.forEach(element => {
                        csv.push({
                            'fecha':element.emision,
                            'fecha':element.fecha,
                            'vencimiento':element.vencimiento,
                            'proveedor':element.proveedor,
                            'numero':element.numero,
                            'pago':element.pago,
                            'productos':element.productos,
                            'total':element.total,
                            'exenta':element.exenta,
                            'usuario':element.usuario
                        })
                    });
                    params = {'datos':csv,'contrato':this.contrato,'archivo':nameFile};
                    response = await axios.post('http://www.app.bodeganet.cl/ApiPHP_ClientesPWA/public/api/inv/csv/nuevo',params);
                    if (response.data=='si'){
                        this.descarga='https://www.app.bodeganet.cl/ApiPHP_ClientesPWA/public/buzon/'+this.contrato+'/'+nameFile+'.csv';
                    }
                break
                case 'Cotizaciones':
                    //titulo
                    csv.push({
                        'fecha':'Fecha',
                        'orden':'Orden',
                        'productos':'Productos',
                        'total':'Total',
                        'usuario':'Usuario'
                    });
                    //datos
                    this.itemsResult.forEach(element => {
                        csv.push({
                            'fecha':element.fecha,
                            'orden':element.orden,
                            'productos':element.productos,
                            'total':element.total,
                            'usuario':element.usuario
                        })
                    });
                    params = {'datos':csv,'contrato':this.contrato,'archivo':nameFile};
                    response = await axios.post('http://www.app.bodeganet.cl/ApiPHP_ClientesPWA/public/api/inv/csv/nuevo',params);
                    if (response.data=='si'){
                        this.descarga='https://www.app.bodeganet.cl/ApiPHP_ClientesPWA/public/buzon/'+this.contrato+'/'+nameFile+'.csv';
                    }
                break
                case 'Ventas':
                    //titulo
                    csv.push({
                        'fecha':'Fecha',
                        'vencimiento':'Vencimiento',
                        'cliente':'Cliente',
                        'tipo':'Documento',
                        'numero':'Numero',
                        'orden':'Orden',
                        'total':'Total',
                        'pago':'Pago',
                        'usuario':'Usuario',
                        'comision':'Comision',
                        'sucursal':'Sucursal'
                    });
                    //datos
                    this.itemsResult.forEach(element => {
                        csv.push({
                            'fecha':element.fecha,
                            'vencimiento':element.vencimiento,
                            'cliente':element.cliente,
                            'tipo':element.tipo,
                            'numero':element.numero,
                            'orden':element.orden,
                            'total':element.total,
                            'pago':element.pago,
                            'usuario':element.usuario,
                            'comision':element.comision,
                            'sucursal':element.sucursal
                        })
                    });
                    params = {'datos':csv,'contrato':this.contrato,'archivo':nameFile};
                    response = await axios.post('http://www.app.bodeganet.cl/ApiPHP_ClientesPWA/public/api/inv/csv/nuevo',params);
                    if (response.data=='si'){
                        this.descarga='https://www.app.bodeganet.cl/ApiPHP_ClientesPWA/public/buzon/'+this.contrato+'/'+nameFile+'.csv';
                    }
                break
                case 'Devoluciones':
                    //titulo
                    csv.push({
                        'fecha':'Fecha',
                        'cliente':'Cliente',
                        'tipo':'Documento',
                        'numero':'Numero',
                        'orden':'N.Orden',
                        'venta':'N.Venta',
                        'total':'Total',
                        'pago':'Pago',
                        'usuario':'Usuario',
                        'sucursal':'Sucursal'
                    });
                    //datos
                    this.itemsResult.forEach(element => {
                        csv.push({
                            'fecha':element.fecha,
                            'cliente':element.cliente,
                            'tipo':element.tipo,
                            'numero':element.numero,
                            'orden':element.orden,
                            'venta':element.venta,
                            'total':element.total,
                            'pago':element.pago,
                            'usuario':element.usuario,
                            'sucursal':element.sucursal
                        })
                    });
                    params = {'datos':csv,'contrato':this.contrato,'archivo':nameFile};
                    response = await axios.post('http://www.app.bodeganet.cl/ApiPHP_ClientesPWA/public/api/inv/csv/nuevo',params);
                    if (response.data=='si'){
                        this.descarga='https://www.app.bodeganet.cl/ApiPHP_ClientesPWA/public/buzon/'+this.contrato+'/'+nameFile+'.csv';
                    }
                break
                case 'Proveedores':
                    //titulo
                    csv.push({
                        'rut':'Rut',
                        'nombre':'Nombre',
                        'email':'Email',
                        'ciudad':'Ciudad',
                        'estado':'Estado',
                        'observa':'Observacion'
                    });
                    //datos
                    this.itemsResult.forEach(element => {
                        csv.push({
                            'rut':element.rut,
                            'nombre':element.nombre,
                            'email':element.email,
                            'ciudad':element.ciudad,
                            'estado':element.estado,
                            'observa':element.observa
                        })
                    });
                    params = {'datos':csv,'contrato':this.contrato,'archivo':nameFile};
                    response = await axios.post('http://www.app.bodeganet.cl/ApiPHP_ClientesPWA/public/api/inv/csv/nuevo',params);
                    if (response.data=='si'){
                        this.descarga='https://www.app.bodeganet.cl/ApiPHP_ClientesPWA/public/buzon/'+this.contrato+'/'+nameFile+'.csv';
                    }
                break
                case 'Clientes':
                    //titulo
                    csv.push({
                        'rut':'Rut',
                        'nombre':'Nombre',
                        'email':'Email',
                        'ciudad':'Ciudad',
                        'estado':'Vigente',
                        'observa':'Observacion'
                    });
                    //datos
                    this.itemsResult.forEach(element => {
                        csv.push({
                            'rut':element.rut,
                            'nombre':element.nombre,
                            'email':element.email,
                            'ciudad':element.ciudad,
                            'estado':element.estado,
                            'observa':element.observa
                        })
                    });
                    params = {'datos':csv,'contrato':this.contrato,'archivo':nameFile};
                    response = await axios.post('http://www.app.bodeganet.cl/ApiPHP_ClientesPWA/public/api/inv/csv/nuevo',params);
                    if (response.data=='si'){
                        this.descarga='https://www.app.bodeganet.cl/ApiPHP_ClientesPWA/public/buzon/'+this.contrato+'/'+nameFile+'.csv';
                    }
                break
                case 'Usuarios':
                    //titulo
                    csv.push({
                        'login':'Login',
                        'nombre':'Nombre',
                        'email':'Email',
                        'comision':'Comision',
                        'estado':'Estado'
                    });
                    //datos
                    this.itemsResult.forEach(element => {
                        csv.push({
                            'login':element.login,
                            'nombre':element.nombre,
                            'email':element.email,
                            'comision':element.comision,
                            'estado':element.estado
                        })
                    });
                    params = {'datos':csv,'contrato':this.contrato,'archivo':nameFile};
                    response = await axios.post('http://www.app.bodeganet.cl/ApiPHP_ClientesPWA/public/api/inv/csv/nuevo',params);
                    if (response.data=='si'){
                        this.descarga='https://www.app.bodeganet.cl/ApiPHP_ClientesPWA/public/buzon/'+this.contrato+'/'+nameFile+'.csv';
                    }
                break
                case 'Credito Cobranza':
                    //titulo
                    csv.push({
                        'emision':'Emision',
                        'vencimiento':'Vencimiento',
                        'rut':'Rut',
                        'nombre':'Nombre',
                        'documento':'Documento',
                        'numero':'Numero',
                        'total':'Total',
                        'cuenta':'Cuentas Por',
                        'estado':'Estado',
                    });
                    //datos
                    this.itemsResult.forEach(element => {
                        csv.push({
                            'emision':element.emision,
                            'vencimiento':element.vencimiento,
                            'rut':element.rut,
                            'nombre':element.nombre,
                            'documento':element.documento,
                            'numero':element.numero,
                            'total':element.total,
                            'cuenta':element.cuenta,
                            'estado':element.estado
                        })
                    });
                    params = {'datos':csv,'contrato':this.contrato,'archivo':nameFile};
                    response = await axios.post('http://www.app.bodeganet.cl/ApiPHP_ClientesPWA/public/api/inv/csv/nuevo',params);
                    if (response.data=='si'){
                        this.descarga='https://www.app.bodeganet.cl/ApiPHP_ClientesPWA/public/buzon/'+this.contrato+'/'+nameFile+'.csv';
                    }
                break
                case 'Lista de Precios':
                    //titulo
                    csv.push({
                        'sku':'Sku',
                        'producto':'Producto',
                        //'marca':'Marca',
                        'categoria':'Categoria',
                        'caracteristica':'Caracteristica',
                        'descripcion':'Descripcion',
                        //'medida':'Unidad Medida',
                        'barra':'Cod. Barra',
                        'universal':'Cod. Universal',
                        'local':'Precio Local',
                        'mayor':'Precio Mayor',
                        'oferta':'Precio Oferta',
                        //'critico':'Stock Critico',
                        'estado':'Estado',
                    });
                    //datos
                    this.itemsResult.forEach(element => {
                        csv.push({
                            'sku':element.sku,
                            'producto':element.producto,
                            //'marca':element.marca,
                            'categoria':element.categoria,
                            'caracteristica':element.caracteristica,
                            'descripcion':element.descripcion,
                            //'medida':element.medida,
                            'barra':element.barra,
                            'universal':element.universal,
                            'local':element.local,
                            'mayor':element.mayor,
                            'oferta':element.oferta,
                            //'critico':element.critico,
                            'estado':element.estado,
                        })
                    });
                    params = {'datos':csv,'contrato':this.contrato,'archivo':nameFile};
                    response = await axios.post('http://www.app.bodeganet.cl/ApiPHP_ClientesPWA/public/api/inv/csv/nuevo',params);
                    if (response.data=='si'){
                        this.descarga='https://www.app.bodeganet.cl/ApiPHP_ClientesPWA/public/buzon/'+this.contrato+'/'+nameFile+'.csv';
                    }
                break
            }
            this.loading=false;
        },
        async verReporte(tipo){
            this.descarga='javascript:void(0)';
            this.descargaCSV=false;
            this.likesearch='';
            this.page=1;
            this.likesearchDialog='';
            this.pageDialog= 1;
            this.loading=true;
            let response;
            let datos;
            if (tipo!=''){
                switch (tipo) {
                  case 'Lista de Precios':
                    this.headers=[];
                    this.items= [];
                    this.headers=[
                        { text: 'Sku', align: 'start', sortable: true, value: 'sku' },
                        { text: 'Producto', align: 'start', sortable: true, value: 'producto' },
                        { text: 'Categoria', align: 'start', sortable: true, value: 'categoria' },
                        //{ text: 'Marca', align: 'start', sortable: true, value: 'marca' },
                        { text: 'Caracteristica', align: 'start', sortable: false, value: 'caracteristica' },
                        //{ text: 'Descripcion', align: 'start', sortable: false, value: 'descripcion' },
                        //{ text: 'Medida', align: 'start', sortable: false, value: 'medida' },
                        { text: 'Cod. Barra', align: 'start', sortable: false, value: 'barra' },
                        { text: 'Cod. Universal', align: 'start', sortable: true, value: 'universal' },
                        { text: 'Precio Local', align: 'start', sortable: false, value: 'local' },
                        { text: 'Precio Mayor', align: 'center', sortable: false, value: 'mayor' },
                        { text: 'Precio Oferta', align: 'start', sortable: false, value: 'oferta' },
                        //{ text: 'Critico', align: 'start', sortable: false, value: 'critico' },
                        { text: 'Estado', align: 'start', sortable: false, value: 'estado' },
                    ]
                    response = await axios.get('http://www.app.bodeganet.cl/ApiPHP_ClientesPWA/public/api/inv/lista/productos/'+this.contrato);
                    datos=response.data;
                    //console.log(datos);
                    if (datos[0].existe=='si'){
                        this.items= [];
                        datos.forEach(element => {
                            let critical='NO';
                            if(element.Critico!=0){
                                critical='SI';
                            }
                            this.items.push({
                                'sku':element.Sku,
                                'producto':element.Nombre,
                                'categoria':element.CategoriaName,
                                'marca': element.Marca,
                                'caracteristica':element.Caracteristica,
                                'descripcion':element.Descripcion,
                                'medida':element.Medida,
                                'barra':element.Barra,
                                'universal':element.Universal,
                                'local':new Intl.NumberFormat("de-DE").format(parseFloat(element.PrecioLocal)),
                                'mayor':new Intl.NumberFormat("de-DE").format(parseFloat(element.PrecioMayor)),
                                'oferta':new Intl.NumberFormat("de-DE").format(parseFloat(element.PrecioOferta)),
                                'critico':element.Critico,
                                'estado':element.Estado,
                            })
                        });
                    }
                  break
                  case 'Credito Cobranza':
                    this.headers=[];
                    this.items= [];
                    this.headers=[
                        { text: 'Emision', align: 'start', sortable: true, value: 'emision' },
                        { text: 'Vencimiento', align: 'start', sortable: true, value: 'vencimiento' },
                        { text: 'Rut', align: 'start', sortable: true, value: 'rut' },
                        { text: 'Nombre', align: 'start', sortable: true, value: 'nombre' },
                        { text: 'Documento', align: 'start', sortable: false, value: 'documento' },
                        { text: 'Numero', align: 'start', sortable: false, value: 'numero' },
                        { text: 'Total ($)', align: 'start', sortable: true, value: 'total' },
                        { text: 'Cuentas Por', align: 'start', sortable: true, value: 'cuenta' },
                        { text: 'Estado', align: 'start', sortable: true, value: 'estado' }
                    ]
                    response = await axios.get('http://www.app.bodeganet.cl/ApiPHP_ClientesPWA/public/api/inv/report/credito/cobranza/'+this.contrato);
                    datos=response.data;
                    if (datos[0].existe=='si'){
                        this.items= [];
                        var vence='';
                        var status='--';
                        const hoy=(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10);
                        datos.forEach(element => {
                            if(parseInt(element.TipoDte)==2){ 
                                vence='--';
                                status=element.Estado
                            }else { 
                                vence=element.Vencimiento;
                                if(new Date(element.Vencimiento)<new Date(hoy) && element.Estado=='Pendiente'){
                                    status='Vencido'
                                } else { status=element.Estado }
                            };
                            this.items.push({
                                'emision':element.Fecha,
                                'vencimiento':vence,
                                'rut':element.Rut,
                                'nombre':element.Razon,
                                'documento':element.Documento,
                                'numero':element.Numero,
                                'total':new Intl.NumberFormat("de-DE").format(parseFloat(element.Total)),
                                'cuenta':element.Cuenta,
                                'estado':status
                            })
                        });
                    }
                    break
                  case 'Utilidades':
                    this.headers=[];
                    this.items= [];
                    this.headers=[
                        { text: 'Fecha', align: 'center', sortable: true, value: 'Fecha' },
                        { text: 'Sku', align: 'start', sortable: true, value: 'Sku' },
                        { text: 'Producto', align: 'start', sortable: true, value: 'Producto' },
                        { text: 'Cantidad', align: 'start', sortable: true, value: 'Cantidad' },
                        { text: 'Precio Compra', align: 'start', sortable: true, value: 'PrecioCompra' },
                        { text: 'Precio Venta', align: 'start', sortable: false, value: 'PrecioVenta' },
                        { text: 'Utilidad', align: 'start', sortable: false, value: 'Utilidad' },
                        { text: 'Bodega', align: 'start', sortable: false, value: 'Bodega' },
                        { text: 'Origen', align: 'start', sortable: false, value: 'Origen' },
                    ];
                    await this.rptGanancias();
                    break
                  case 'Ganancias':
                    this.headers=[];
                    this.items= [];
                    this.headers=[
                        { text: 'Fecha', align: 'center', sortable: true, value: 'Fecha' },
                        { text: 'Sku', align: 'start', sortable: true, value: 'Sku' },
                        { text: 'Producto', align: 'start', sortable: true, value: 'Producto' },
                        { text: 'Cantidad', align: 'start', sortable: true, value: 'Cantidad' },
                        { text: 'Precio Compra', align: 'start', sortable: true, value: 'PrecioCompra' },
                        { text: 'Precio Venta', align: 'start', sortable: false, value: 'PrecioVenta' },
                        { text: 'Utilidad', align: 'start', sortable: false, value: 'Utilidad' },
                        { text: 'Bodega', align: 'start', sortable: false, value: 'Bodega' },
                        { text: 'Origen', align: 'start', sortable: false, value: 'Origen' },
                    ];
                    await this.Util();
                    break
                  case 'Utilidad':
                    this.headers=[];
                    this.items= [];
                    this.headers=[
                        { text: 'Fecha', align: 'center', sortable: true, value: 'fecha' },
                        { text: 'Sku', align: 'left', sortable: true, value: 'sku' },
                        { text: 'Producto', align: 'left', sortable: true, value: 'producto' },
                        { text: 'Cantidad', align: 'start', sortable: false, value: 'cantidad' },
                        { text: 'Compra', align: 'start', sortable: false, value: 'compra' },
                        { text: 'Venta', align: 'start', sortable: false, value: 'venta' },
                        //{ text: 'Devolucion', align: 'center', sortable: false, value: 'devolucion' },
                        { text: 'Utilidad', align: 'start', sortable: true, value: 'utilidad' },
                        { text: 'Sucursal', align: 'start', sortable: true, value: 'sucursal' }
                    ]
                    response = await axios.get('http://www.app.bodeganet.cl/ApiPHP_ClientesPWA/public/api/inv/report/utilidad/detalle/v3/'+this.contrato+'/'+this.date1+'/'+this.date2);
                    datos=response.data;
                    //console.log(datos);
                    if (datos[0].existe=='si'){
                        this.items= [];
                        datos.forEach(element => {
                            //console.log(element.Fecha,this.date1,this.date2);
                            if (element.Fecha>=this.date1 && element.Fecha<=this.date2 && element.Sucursal!='Reciclaje'){
                                //console.log(element.Fecha,this.date1);
                                this.items.push({
                                    'fecha':element.Fecha,
                                    'sku':element.Sku,
                                    'producto':element.Producto,
                                    'cantidad':element.Cantidad,
                                    'compra':new Intl.NumberFormat("de-DE").format(parseFloat(element.Compra)),
                                    'venta':new Intl.NumberFormat("de-DE").format(parseFloat(element.Venta)),
                                    //'devolucion':new Intl.NumberFormat("de-DE").format(parseFloat(element.Devolucion)),
                                    'utilidad':element.Utilidad,
                                    'sucursal':element.Sucursal
                                })
                            } 
                        });
                    }
                    break
                  case 'Stock':
                    this.headers=[];
                    this.items= [];
                    this.headers=[
                        { text: 'Sku', align: 'left', sortable: true, value: 'sku' },
                        { text: 'Producto', align: 'left', sortable: true, value: 'producto' },
                        { text: 'Bodega', align: 'left', sortable: true, value: 'bodega' },
                        { text: 'Stock', align: 'start', sortable: false, value: 'stock' },
                        { text: 'U12M', align: 'start', sortable: false, value: 'u12m' },
                        { text: 'PU12M', align: 'start', sortable: false, value: 'pu12m' },
                        { text: 'U30D', align: 'center', sortable: false, value: 'u30d' },
                        { text: 'U90D', align: 'start', sortable: false, value: 'u90d' },
                        { text: 'P3M', align: 'start', sortable: false, value: 'p3m' },
                        { text: 'Critico', align: 'start', sortable: true, value: 'critico' }
                    ]
                    response = await axios.get('http://www.app.bodeganet.cl/ApiPHP_ClientesPWA/public/api/inv/report/calculostock/'+this.contrato);
                    datos=response.data;
                    //console.log(datos);
                    if (datos[0].existe=='si'){
                        this.items= [];
                        datos.forEach(element => {
                            let critical='NO';
                            if(element.Critico!=0){
                                critical='SI';
                            }
                            this.items.push({
                                'sku':element.Sku,
                                'producto':element.Producto,
                                'bodega':element.Bodega,
                                'stock':new Intl.NumberFormat("de-DE").format(parseFloat(element.Stock)),
                                'u12m':new Intl.NumberFormat("de-DE").format(parseFloat(element.U12M)),
                                'pu12m':new Intl.NumberFormat("de-DE").format(parseFloat(element.PU12M)),
                                'u30d':new Intl.NumberFormat("de-DE").format(parseFloat(element.U30D)),
                                'u90d':new Intl.NumberFormat("de-DE").format(parseFloat(element.U90D)),
                                'p3m':new Intl.NumberFormat("de-DE").format(parseFloat(element.P3M)),
                                'critico':critical
                            })
                        });
                    }
                    break
                  case 'Productos':
                    this.headers=[];
                    this.items= [];
                    this.headers=[
                        { text: 'Fecha', align: 'center', sortable: true, value: 'fecha' },
                        { text: 'Sku', align: 'center', sortable: true, value: 'sku' },
                        { text: 'Producto', align: 'start', sortable: true, value: 'producto' },
                        { text: 'Movimiento', align: 'start', sortable: true, value: 'movimiento' },
                        { text: 'Entidad', align: 'start', sortable: false, value: 'entidad' },
                        { text: 'Cantidad', align: 'center', sortable: true, value: 'cantidad' },
                        { text: 'Precio', align: 'start', sortable: false, value: 'precio' },
                        { text: 'Bodega', align: 'start', sortable: false, value: 'bodega' },
                        { text: 'Usuario', align: 'start', sortable: false, value: 'usuario' },
                        { text: 'Observa', align: 'start', sortable: false, value: 'observa' },
                    ]
                    response = await axios.get('http://www.app.bodeganet.cl/ApiPHP_ClientesPWA/public/api/inv/report/productos/'+this.contrato+'/'+this.date1+'/'+this.date2);
                    datos=response.data;
                    //console.log(datos);
                    if (datos[0].existe=='si'){
                        this.items= [];
                        datos.forEach(element => {
                            this.items.push({
                                'fecha':element.Fecha,
                                'sku':element.Sku,
                                'producto':element.Producto,
                                'movimiento':element.Movimiento,
                                'entidad':element.Entidad,
                                'cantidad':new Intl.NumberFormat("de-DE").format(parseFloat(element.Cantidad)),
                                'precio':new Intl.NumberFormat("de-DE").format(parseFloat(element.Precio)),
                                'bodega':element.Bodega,
                                'usuario':element.Usuario,
                                'observa':element.Observa
                            })
                        });
                    }
                    break
                  case 'Compras':
                    this.headers=[];
                    this.items= [];
                    this.headers=[
                        { text: 'Emision', align: 'center', sortable: true, value: 'emision' },
                        { text: 'Ingreso', align: 'center', sortable: true, value: 'fecha' },
                        { text: 'Vencimiento', align: 'center', sortable: true, value: 'vencimiento' },
                        { text: 'Proveedor', align: 'start', sortable: true, value: 'proveedor' },
                        { text: 'Numero', align: 'center', sortable: true, value: 'numero' },
                        { text: 'Pago', align: 'center', sortable: true, value: 'pago' },
                        { text: 'Productos', align: 'start', sortable: true, value: 'productos' },
                        { text: 'Total', align: 'center', sortable: true, value: 'total' },
                        { text: 'Exenta', align: 'center', sortable: true, value: 'exenta' },
                        { text: 'Usuario', align: 'start', sortable: false, value: 'usuario' },
                        { text: 'Detalle', align: 'start', sortable: false, value: 'detalle' },
                        { text: 'Ver', align: 'start', sortable: false, value: 'ruta' },
                    ]
                    response = await axios.get('http://www.app.bodeganet.cl/ApiPHP_ClientesPWA/public/api/inv/report/cc/C/'+this.contrato+'/'+this.date1+'/'+this.date2);
                    datos=response.data;
                    if (datos[0].existe=='si'){
                        datos.forEach(element => {
                            if (parseInt(element.Productos)!=0){
                                let html='http://www.app.bodeganet.cl/ApiPHP_ClientesPWA/public/api/inv/prt/compra/'+this.contrato+'/'+element.Orden+'/'+element.Entidad;
                                this.items.push({
                                    'entidad':element.Entidad,
                                    'emision':element.Emision,
                                    'fecha':element.Fecha,
                                    'vencimiento':element.Fecha_Pago,
                                    'proveedor':element.Proveedor,
                                    'numero':element.Orden,
                                    'pago':element.Forma_Pago,
                                    'productos':element.Productos,
                                    'total':new Intl.NumberFormat("de-DE").format(parseFloat(element.Total)),
                                    'exenta':element.Exenta,
                                    'usuario':element.Usuario,
                                    'ruta':html
                                })
                            }
                        });
                    }
                    break
                  case 'Cotizaciones':
                    this.headers=[];
                    this.items= [];
                    this.headers=[
                        { text: 'Fecha', align: 'center', sortable: true, value: 'fecha' },
                        { text: 'Orden', align: 'center', sortable: true, value: 'orden' },
                        { text: 'Productos', align: 'center', sortable: true, value: 'productos' },
                        { text: 'Total', align: 'center', sortable: true, value: 'total' },
                        { text: 'Usuario', align: 'start', sortable: false, value: 'usuario' },
                        { text: 'Detalle', align: 'start', sortable: false, value: 'detalle' },
                        { text: 'Ver', align: 'start', sortable: false, value: 'ruta' },
                    ]
                    response = await axios.get('http://www.app.bodeganet.cl/ApiPHP_ClientesPWA/public/api/inv/report/cc/V/'+this.contrato+'/'+this.date1+'/'+this.date2);
                    datos=response.data;
                    if (datos[0].existe=='si'){
                        datos.forEach(element => {
                            if (parseInt(element.Productos)!=0){
                                let html='http://www.app.bodeganet.cl/ApiPHP_ClientesPWA/public/api/inv/prt/cotiza/'+this.contrato+'/'+element.Orden+'/'+element.Entidad; 
                                this.items.push({
                                    'entidad':element.Entidad,
                                    'fecha':element.Fecha,
                                    'orden':element.Orden,
                                    'productos':element.Productos,
                                    'total':new Intl.NumberFormat("de-DE").format(parseFloat(element.Total)),
                                    'usuario':element.Usuario,
                                    'ruta':html
                                })
                            }
                        });
                    }
                    break
                  case 'Ventas':
                    this.headers=[];
                    this.items= [];
                    this.headers=[
                        { text: 'Fecha', align: 'center', sortable: true, value: 'fecha' },
                        { text: 'Vencimiento', align: 'center', sortable: true, value: 'vencimiento' },
                        { text: 'Cliente', align: 'start', sortable: true, value: 'cliente' },
                        { text: 'Documento', align: 'start', sortable: true, value: 'tipo' },
                        { text: 'Numero', align: 'center', sortable: true, value: 'numero' },
                        { text: 'Orden', align: 'center', sortable: true, value: 'orden' },
                        { text: 'Total', align: 'center', sortable: true, value: 'total' },
                        { text: 'Pago', align: 'start', sortable: true, value: 'pago' },
                        { text: 'Usuario', align: 'start', sortable: false, value: 'usuario' },
                        { text: 'Comision', align: 'center', sortable: false, value: 'comision' },
                        { text: 'Sucursal', align: 'start', sortable: true, value: 'sucursal' },
                        { text: 'Detalle', align: 'center', sortable: false, value: 'detalle' },
                        { text: 'Ver', align: 'center', sortable: false, value: 'ruta' },
                    ]
                    response = await axios.get('http://www.app.bodeganet.cl/ApiPHP_ClientesPWA/public/api/inv/report/dte/in/'+this.contrato+'/'+this.date1+'/'+this.date2);
                    datos=response.data;
                    if (datos[0].existe=='si'){
                        datos.forEach(element => {
                            this.items.push({
                                'entidad':element.Entidad,
                                'fecha':element.Fecha,
                                'vencimiento':element.Vencimiento,
                                'cliente':element.Cliente,
                                'tipo':element.Documento,
                                'numero':element.Numero,
                                'orden':element.Orden,
                                'total':new Intl.NumberFormat("de-DE").format(parseFloat(element.Total)),
                                'pago':element.Forma,
                                'usuario':element.Usuario,
                                'comision':element.Comision,
                                'sucursal':element.Suc,
                                'ruta':element.RutaPdf
                            })
                        });
                    }                      
                    break
                  case 'Devoluciones':
                    this.headers=[];
                    this.items= [];
                    this.headers=[
                        { text: 'Fecha', align: 'center', sortable: true, value: 'fecha' },
                        { text: 'Cliente', align: 'center', sortable: true, value: 'cliente' },
                        { text: 'Documento', align: 'start', sortable: true, value: 'tipo' },
                        { text: 'Numero', align: 'center', sortable: true, value: 'numero' },
                        { text: 'N° Orden', align: 'center', sortable: true, value: 'orden' },
                        { text: 'N° Venta', align: 'center', sortable: true, value: 'venta' },                        
                        { text: 'Total', align: 'center', sortable: true, value: 'total' },
                        { text: 'Pago', align: 'center', sortable: true, value: 'pago' },
                        { text: 'Usuario', align: 'start', sortable: false, value: 'usuario' },
                        { text: 'Sucursal', align: 'start', sortable: true, value: 'sucursal' },
                        { text: 'Detalle', align: 'start', sortable: false, value: 'detalle' },
                        { text: 'Ver', align: 'start', sortable: false, value: 'ruta' },
                    ]
                    response = await axios.get('http://www.app.bodeganet.cl/ApiPHP_ClientesPWA/public/api/inv/report/dte/out/'+this.contrato+'/'+this.date1+'/'+this.date2);
                    datos=response.data;
                    if (datos[0].existe=='si'){
                        datos.forEach(element => {
                            this.items.push({
                                'entidad':element.Entidad,
                                'fecha':element.Fecha,
                                'cliente':element.Cliente,
                                'tipo':element.Documento,
                                'numero':element.Numero,
                                'orden':element.Orden,
                                'venta':element.nVenta,
                                'total':new Intl.NumberFormat("de-DE").format(parseFloat(element.Total)),
                                'pago':element.Forma,
                                'usuario':element.Usuario,
                                'sucursal':element.Suc,
                                'ruta':element.RutaPdf
                            })
                        });
                    }                      
                    break
                  case 'Proveedores':
                    this.headers=[];
                    this.items= [];
                    this.headers=[
                        { text: 'Rut', align: 'start', sortable: true, value: 'rut' },
                        { text: 'Nombre', align: 'start', sortable: true, value: 'nombre' },
                        { text: 'Email', align: 'start', sortable: true, value: 'email' },
                        { text: 'Ciudad', align: 'start', sortable: true, value: 'ciudad' },
                        { text: 'Vigente', align: 'center', sortable: false, value: 'estado' },
                        { text: 'Observacion', align: 'start', sortable: false, value: 'observa' },
                    ]
                    response = await axios.get('http://www.app.bodeganet.cl/ApiPHP_ClientesPWA/public/api/inv/report/entidad/Proveedor/'+this.contrato+'/'+this.date1+'/'+this.date2);
                    datos=response.data;
                    if (datos[0].existe=='si'){
                        datos.forEach(element => {
                            this.items.push({
                                'rut':element.Rut,
                                'nombre':element.Razon,
                                'email':element.Email,
                                'ciudad':element.Ciudad,
                                'estado':element.Estado,
                                'observa':element.Observa
                            })
                        });
                    }                      
                    break
                  case 'Clientes':
                    this.headers=[];
                    this.items= [];
                    this.headers=[
                        { text: 'Rut', align: 'start', sortable: true, value: 'rut' },
                        { text: 'Nombre', align: 'start', sortable: true, value: 'nombre' },
                        { text: 'Email', align: 'start', sortable: true, value: 'email' },
                        { text: 'Ciudad', align: 'start', sortable: true, value: 'ciudad' },
                        { text: 'Vigente', align: 'center', sortable: false, value: 'estado' },
                        { text: 'Observacion', align: 'start', sortable: false, value: 'observa' },
                    ]
                    response = await axios.get('http://www.app.bodeganet.cl/ApiPHP_ClientesPWA/public/api/inv/report/entidad/Cliente/'+this.contrato+'/'+this.date1+'/'+this.date2);
                    datos=response.data;
                    if (datos[0].existe=='si'){
                        datos.forEach(element => {
                            this.items.push({
                                'rut':element.Rut,
                                'nombre':element.Nombre,
                                'email':element.Email,
                                'ciudad':element.Ciudad,
                                'estado':element.Estado,
                                'observa':element.Observa
                            })
                        });
                    }                      
                    break
                  case 'Usuarios':
                    this.headers=[];
                    this.items= [];
                    this.headers=[
                        { text: 'Login', align: 'start', sortable: true, value: 'login' },
                        { text: 'Nombre', align: 'start', sortable: true, value: 'nombre' },
                        { text: 'Email', align: 'start', sortable: true, value: 'email' },
                        { text: 'Comision', align: 'start', sortable: true, value: 'comision' },
                        { text: 'Vigente', align: 'center', sortable: false, value: 'estado' },
                    ]
                    response = await axios.get('http://www.app.bodeganet.cl/ApiPHP_ClientesPWA/public/api/inv/report/entidad/Usuario/'+this.contrato+'/'+this.date1+'/'+this.date2);
                    datos=response.data;
                    if (datos[0].existe=='si'){
                        datos.forEach(element => {
                            this.items.push({
                                'login':element.Usuario,
                                'nombre':element.Nombre,
                                'email':element.Email,
                                'comision':new Intl.NumberFormat("de-DE").format(parseFloat(element.Comision)),
                                'estado':element.Estado
                            })
                        });
                    }                      
                    break
                };
                let varUsuario=this.usu;
                if (this.fullReport!==true){
                    this.itemsResult = this.items.filter(function(element){
                        return element.usuario==varUsuario;
                    });
                } else { this.itemsResult = this.items }
            } else {
                this.Msg='Seleccione tipo Reporte'
                this.color='red';
                this.msgbox=true;
                this.showSnack();
            }
            this.loading=false;
        },


 // funciones de reporteria
    async rptGanancias(){
        this.ganancias=[]
        await this.utilidad_final();
        if(this.ganancias.length!=0){
            //filtramos por fechas solicitadas
            let filtro = this.ganancias.filter(res => new Date(res.fecha+'T00:00:00')>=new Date(this.date1+'T00:00:00') &&  new Date(res.fecha+'T00:00:00')<=new Date(this.date2+'T00:00:00'));
            if(filtro){
                filtro.forEach(element => {
                    this.items.push({
                        'Fecha':element.fecha,
                        'Sku':element.sku,
                        'Producto':element.producto,
                        'Cantidad':new Intl.NumberFormat("de-DE").format(parseFloat(element.cantidad)),
                        'PrecioCompra':new Intl.NumberFormat("de-DE").format(parseFloat(element.compra)),
                        'PrecioVenta':new Intl.NumberFormat("de-DE").format(parseFloat(element.venta)),
                        'Utilidad':new Intl.NumberFormat("de-DE").format(parseFloat(element.utilidad)),
                        'Bodega':element.bodega,
                        'Origen':element.origen
                    })
                });
            }
        }
   },
    async utilidad_final(){
      try { 
        this.ganancias=[];
        const res = await axios.get('http://www.app.bodeganet.cl/ApiPHP_ClientesPWA/public/api/inv/movimientos/productos/utilidad/'+this.contrato);
        const dato=res.data;
        if(dato.length!=0){
          this.movimientos=dato;
          // una vez obtenidos todos los movimientos
          // buscarmos todos los SKU  
          const res1 = await axios.get('http://www.app.bodeganet.cl/ApiPHP_ClientesPWA/public/api/inv/utilidad/sku/all/'+ this.contrato);
          const dato1=res1.data;
          if(dato1.length!=0){
            this.productos=dato1;
            //await this.oneSku('LB00002');
            //comenzamos el recorrido por sku
            for (let index = 0; index < this.productos.length; index++) {
              const element = this.productos[index];
              await this.oneSku(element.Sku);
            }
          }
        };
        //console.log(this.ganancias);
      } catch (error) {
        console.log(error)
      }
    },
    async oneSku(tSku){
      var nSaldo=0;
      var nCompras=0;
      var nVentas=0;
      var nUtilidad=0;
      var nPrecio=0;
      var arraycompras=[];
      var puntero=0;
      var arrayutilidad=[];
      const arrayIN = this.movimientos.filter(res => res.Sku==tSku);
      if(arrayIN){
        arraycompras.push({
          'sku':'sku',
          'valor':'0',
          'unidades':'0'
        });
        for (let index = 0; index < arrayIN.length; index++) {
          const result = arrayIN[index];
          switch (result.Movimiento) {
            case 'Ingreso':
              nSaldo=parseFloat(nSaldo)+parseFloat(result.Cantidad);
              break;
            case 'Egreso':
              nSaldo=parseFloat(nSaldo)-parseFloat(result.Cantidad);
              break;
            case 'Reciclaje':
              nSaldo=parseFloat(nSaldo)-parseFloat(result.Cantidad);
              break;
            case 'Compra':
              // llenamos el array de compras
              arraycompras.push({
                'fecha':result.Fecha,
                'sku':result.Sku,
                'valor':result.Precio,
                'unidades':result.Cantidad
              });
              break;
            case 'Venta':
              if(parseFloat(result.Cantidad)<=parseFloat(nSaldo)){
                nVentas=parseFloat(result.Precio)*parseFloat(result.Cantidad);
                nCompras=parseFloat(nPrecio)*parseFloat(result.Cantidad);
                nUtilidad=parseFloat(nVentas)-parseFloat(nCompras);
                arrayutilidad.push({
                  'fecha':result.Fecha,
                  'sku':result.Sku,
                  'producto':result.Producto,
                  'cantidad':parseFloat(result.Cantidad),
                  'compra':parseFloat(nPrecio),
                  'venta':parseFloat(result.Precio),
                  'utilidad':parseFloat(nUtilidad),
                  'bodega':result.Bodega,
                  'origen':result.Movimiento
                });
                nSaldo=parseFloat(nSaldo)-parseFloat(result.Cantidad);
              } else {
                //recorrer cantidad y verificar saldo vs precio
                for (let index = 0; index < parseFloat(result.Cantidad); index++) {
                  if (parseFloat(nSaldo)<=0 && arraycompras.length>(puntero+1)){
                    puntero++;
                    nSaldo=parseFloat(nSaldo)+parseFloat(arraycompras[puntero].unidades);
                    nPrecio=parseFloat(arraycompras[puntero].valor);
                  };
                  nVentas=parseFloat(result.Precio)*1;
                  nCompras=parseFloat(nPrecio)*1;
                  nUtilidad=parseFloat(nVentas)-parseFloat(nCompras);
                  arrayutilidad.push({
                  'fecha':result.Fecha,
                  'sku':result.Sku,
                  'producto':result.Producto,
                  'cantidad':1,
                  'compra':parseFloat(nPrecio),
                  'venta':parseFloat(result.Precio),
                  'utilidad':parseFloat(nUtilidad),
                  'bodega':result.Bodega,
                  'origen':result.Movimiento
                  });
                  nSaldo=parseFloat(nSaldo)-1;
                };
              }
            break;
            case 'Devolucion':
              nVentas=parseFloat(result.Precio)*parseFloat(result.Cantidad);
              nCompras=parseFloat(nPrecio)*parseFloat(result.Cantidad);
              nUtilidad=parseFloat(nVentas)-parseFloat(nCompras);
              nUtilidad=nUtilidad*-1;
              arrayutilidad.push({
                  'fecha':result.Fecha,
                  'sku':result.Sku,
                  'producto':result.Producto,
                  'cantidad':parseFloat(result.Cantidad),
                  'compra':parseFloat(nPrecio),
                  'venta':parseFloat(result.Precio),
                  'utilidad':parseFloat(nUtilidad),
                  'bodega':result.Bodega,
                  'origen':result.Movimiento
              });
              nSaldo=parseFloat(nSaldo)+parseFloat(result.Cantidad);
              break;
          };
        };
      };
      this.ganancias = this.ganancias.concat(arrayutilidad);
    },






        async Util(){
            try {
                // obtenemos todos los sku
                const response = await axios.get('http://www.app.bodeganet.cl/ApiPHP_ClientesPWA/public/api/inv/utilidad/sku/all/'+ this.contrato);
                const datos=response.data;
                if(datos.length!=0){
                this.codigos=datos;
                //console.log(this.codigos);
                // obtenemos todos las entradas
                const response1 = await axios.get('http://www.app.bodeganet.cl/ApiPHP_ClientesPWA/public/api/inv/utilidad/entradas/'+ this.contrato);
                const datos1=response1.data;
                if(datos1.length!=0){
                    this.entradas=datos1;
                    //console.log(this.entradas);
                };
                // obtenemos las salidas
                const response2 = await axios.get('http://www.app.bodeganet.cl/ApiPHP_ClientesPWA/public/api/inv/utilidad/salidas/'+ this.contrato);
                const datos2=response2.data;
                if(datos2.length!=0){
                    this.salidas=datos2;
                    //console.log(this.salidas);
                };
                let HoraInicio = new Date();
                console.log('INICIO');
                console.log(HoraInicio);
                //comenzamos el recorrido por sku
                let arraySalida=[];
                this.codigos.forEach(element => {
                    const nSku=element.Sku;
                    //rescatamos las entradas
                    const arrayIN = this.entradas.filter(res => res.Sku==nSku);
                    if(arrayIN){
                    // preparamos la entrada
                    let ini=0;
                    let fin=0;
                    let valor=0;
                    let arrayEntrada=[];
                    for (let index = 0; index < arrayIN.length; index++) {
                        const res = arrayIN[index];
                        ini=fin+1;
                        fin=fin+parseFloat(res.Cantidad);
                        valor=res.Precio;
                        arrayEntrada.push({
                            'sku':res.Sku,
                            'inicio':parseFloat(ini),
                            'final':parseFloat(fin),
                            'precio':parseFloat(valor)
                        });                
                    };
                    //console.log(arrayEntrada);
                    // recorremos las Salidas
                    const arrayOUT = this.salidas.filter(res => res.Sku==nSku);
                    if(arrayOUT){
                        let fec='';
                        let rango=0;
                        let precio_venta=0;
                        let precio_compra=0;
                        let utilidad=0;
                        for (let index = 0; index < arrayOUT.length; index++) {
                        const res1 = arrayOUT[index];
                        fec=res1.Fecha;
                        if(res1.Origen=='Venta' || res1.Origen=='Devolucion'){
                            precio_venta=parseFloat(res1.Precio);
                            for (let index = 0; index < res1.Cantidad; index++) {  
                            if(res1.Origen=='Venta'){
                                rango=parseInt(rango)+1;
                                //buscar precio compra segun rango
                                const found = arrayEntrada.find(res => rango>=res.inicio && rango<=res.final);
                                if(found){ precio_compra=parseFloat(found.precio) };
                                utilidad=parseFloat(precio_venta)-parseFloat(precio_compra);
                            };
                            if(res1.Origen=='Devolucion'){
                                rango=parseInt(rango)-1;
                                //buscar precio compra segun rango
                                const found = arrayEntrada.find(res => rango>=res.inicio && rango<=res.final);
                                if(found){ precio_compra=parseFloat(found.precio) };
                                utilidad=parseFloat(precio_compra)-parseFloat(precio_venta);
                                // cambiar signo por devolucion
                                precio_venta=parseFloat(precio_venta)*-1
                            }             
                            //array utilidad
                            arraySalida.push({
                                'Fecha':fec,
                                'Sku': res1.Sku,
                                'Producto':res1.Producto, 
                                'Cantidad':1, 
                                'PrecioCompra':precio_compra,
                                'PrecioVenta':precio_venta,
                                'Utilidad':utilidad,
                                'Bodega':res1.Bodega,
                                'Origen':res1.Origen
                            })
                            }
                        };
                        if(res1.Origen=='Egreso'){
                            rango=parseInt(rango)-parseFloat(res1.Cantidad);
                        }            
                        }
                    }
                    }
                });
                //filtrar la salida segun fechas
                let arraySpecial=[];
                let filtro = arraySalida.filter(res => new Date(res.Fecha+'T00:00:00')>=new Date(this.date1+'T00:00:00') &&  new Date(res.Fecha+'T00:00:00')<=new Date(this.date2+'T00:00:00'));
                if(filtro.length!=0){
                    // agrupar Fecha, Sku 
                    filtro.forEach(el => {
                        const found = arraySpecial.find(
                            resp => 
                            resp.Fecha==el.Fecha && 
                            resp.Sku==el.Sku &&
                            resp.PrecioCompra==el.PrecioCompra &&
                            resp.PrecioVenta==el.PrecioVenta &&
                            resp.Bodega==el.Bodega &&
                            resp.Origen==el.Origen
                        );
                        if(found){ 
                            // ya esta grabado
                        } else {
                            // agrupar y graba
                            let arrayFinal = filtro.filter(
                                rest => 
                                rest.Fecha==el.Fecha && 
                                rest.Sku==el.Sku &&
                                rest.PrecioCompra==el.PrecioCompra &&
                                rest.PrecioVenta==el.PrecioVenta &&
                                rest.Bodega==el.Bodega &&
                                rest.Origen==el.Origen
                            );
                            if(arrayFinal.length!=0){
                                let xSuma=0;
                                for (let index = 0; index < arrayFinal.length; index++) {
                                    const elem = arrayFinal[index];
                                    xSuma=parseFloat(xSuma)+parseFloat(elem.Utilidad);                                  
                                };
                                arraySpecial.push({
                                    'Fecha':el.Fecha,
                                    'Sku': el.Sku,
                                    'Producto':el.Producto,
                                    'Cantidad':arrayFinal.length,
                                    'PrecioCompra':el.PrecioCompra,
                                    'PrecioVenta':el.PrecioVenta,
                                    'Utilidad':xSuma,
                                    'Bodega':el.Bodega,
                                    'Origen':el.Origen
                                })
                            }
                        }   
                    })                    
                };
                //this.items=arraySalida;
                this.items=arraySpecial;
                console.log('FIN');
                let now = new Date();
                console.log(now);
                };                
            } catch (error) {
                console.log(error)
            }
        }
    }
}
</script>